import TemplatesApi from '@/api/templates'

function getDefault () {
    return {
        dynamicValues: [],
        iterableContext: false,
        template: {
            ID: null,
            Label: '',
            Type: 'ticket',
            Area: {
                Width: 9.0,
                Height: 5.0,
                PageBreakHeight: 0,
                HeaderHeight: 0,
                FooterHeight: 0,
                Background: {
                    StoredFileName: '',
                    OriginalFileName: '',
                    DateCreated: null
                },
                BackgroundCover: 'cover'
            },
            Holders: [],
            Editable: true
        },
        focusedHolder: null,
        focusedBlock: null,
        zoom: 100

    }
}

const state = getDefault()

const mutations = {
    clear (state) {
        Object.assign(state, getDefault())
    },
    resetTemplate (state) {
        state.template = getDefault().template
    },
    setDynamicValues (state, dynamicValues) {
        state.dynamicValues = dynamicValues
    },
    setTemplate (state, template) {
        state.template = template
    },
    updateTemplateArea (state, area) {
        state.template.Area = Object.assign({}, state.template.Area, area)
    },
    addHolder (state, holder) {
        state.template.Holders.push(holder)
    },
    updateHolder (state, update) {
        const holder = state.template.Holders.find(h => h.ID === update.ID)
        Object.assign(holder, update)
    },
    unfocus (state) {
        state.focusedHolder = null
        state.focusedBlock = null
    },
    setIterableContext (state, iterableContext) {
        state.iterableContext = iterableContext
    },
    focusBlock (state, block) {
        // reset iterale context
        state.iterableContext = false

        state.focusedBlock = block
    },
    focusHolder (state, holderID) {
        state.focusedHolder = state.template.Holders.find(h => h.ID === holderID)
    },
    deleteFocusedHolder (state) {
        const focusedHolderIndex = state.template.Holders.findIndex(h => h === state.focusedHolder)
        state.template.Holders.splice(focusedHolderIndex, 1)
        state.focusedHolder = null
        state.focusedBlock = null
    },
    zoomIn (state) {
        if (state.zoom + 25 <= 200) state.zoom += 25
    },
    zoomOut (state) {
        if (state.zoom - 25 >= 25) state.zoom -= 25
    }
}

const actions = {
    loadDynamicValues ({ commit }) {
        return new Promise((resolve, reject) => {
            TemplatesApi.getDynamicValues()
                .then((res) => {
                    commit('setDynamicValues', res.data)
                    resolve()
                })
                .catch((err) =>
                    reject(err)
                )
        })
    },
    loadTemplate ({ commit, state }, id) {
        return new Promise((resolve, reject) => {
            if (state.template.ID !== id) {
                TemplatesApi.getTemplate(id)
                    .then((res) => {
                        // save
                        commit('setTemplate', res.data)
                        resolve(res.data)
                    })
                    .catch((err) =>
                        reject(err)
                    )
            } else {
                resolve(state.template)
            }
        })
    },

    upsertTemplate ({ commit, state }) {
        return new Promise((resolve, reject) => {
            if (state.template.ID) {
                TemplatesApi.updateTemplate(state.template.ID, state.template)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) =>
                        reject(err)
                    )
            } else {
                // Create and redirect URL
                TemplatesApi.createTemplate(state.template)
                    .then((res) => {
                        commit('setTemplate', res.data)
                        resolve()
                    })
                    .catch((err) =>
                        reject(err)
                    )
            }
        })
    },

    getPreview ({ state }) {
        return new Promise((resolve, reject) => {
            TemplatesApi.getTemplatePreview(state.template.ID)
                .then((res) => {
                    const url = window.URL.createObjectURL(
                        new Blob([res.data])
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', 'preview.pdf') // or any other extension
                    document.body.appendChild(link)
                    link.click()
                    resolve()
                })
                .catch((err) =>
                    reject(err)
                )
        })
    }
}

const getters = {
    dynamicValues (state) {
        const templateDynamicValues = []
        state.dynamicValues.forEach(v => {
            const entry = Object.assign({}, v)
            const inIterable = v.IterableAvailability && Object.keys(v.IterableAvailability).includes(state.template.Type)
            if (inIterable) {
                entry.IterableAvailability = v.IterableAvailability[state.template.Type]
            } else {
                delete entry.IterableAvailability
            }
            const inSingle = v.SingleAvailability && v.SingleAvailability.includes(state.template.Type)

            if (inIterable || inSingle) templateDynamicValues.push(entry)
        })

        return templateDynamicValues
    },

    contextDynamicValues (state, getters) {
        return getters.dynamicValues.filter(v => {
            let available = false

            if (state.iterableContext) {
                available = v.IterableAvailability && v.IterableAvailability.includes(state.focusedHolder.MainBlock.Type)
            } else {
                available = v.SingleAvailability && v.SingleAvailability.includes(state.template.Type)
            }

            if (state.focusedBlock) {
                available = available && state.focusedBlock.Type === v.BlockType
            }

            return available
        }) ?? []
    },

    existIterableDynamicValues (state) {
        return state.dynamicValues.some(v => v.IterableAvailability && Object.keys(v.IterableAvailability).includes(state.template.Type))
    },

    getHolderByID: (state) => (id) => {
        return state.template.Holders.find(h => h.ID === id)
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
