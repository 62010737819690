import date from './date'
import constants from '../consts'

var self = {
    isSubscriptionValid(subscription, productData) {
        let product
        if (!productData){
            console.warn("Cannot check product subscription type for "+subscription.ID+", please provide product map")
        } else {
            product = productData.ID == subscription.ProductID ? productData : productData[subscription.ProductID]
        }

        return (
            (!subscription.Discontinued && !subscription.Paused) &&
            (subscription.SubscriptionLimitDate == null || date.isFuture(subscription.SubscriptionLimitDate)) &&
            (subscription.SubscriptionStartDate == null || date.isPast(subscription.SubscriptionStartDate)) &&
            (subscription.Quantity > 0 || (product && product.Subscription && product.Subscription.Type === constants.SUBSCRIPTION_TYPE_SUBSCRIPTION))
        );
    },


    isSubscriptionCompatibleFor(subscription, bookingSlot, activity) {
        return (
            bookingSlot.Products.includes(subscription.ProductID) || (activity && activity.Products.includes(subscription.ProductID))
        );
    },

    // getClientCompatibleSubscriptions search for non-expired subscriptions on client that are compatible with the booking slot
    getClientCompatibleSubscriptions(client, bookingSlot, activity, productsMap) {
        if(!client || !client.Cards) {
            return []
        }
        const subs = []
        for (const c of client.Cards) {
            if(!c.Subscriptions){
                continue
            }
            for (const s of c.Subscriptions) {
                if (this.isSubscriptionValidFor(s, bookingSlot, activity, productsMap)) {
                    subs.push(s)
                }
            }
        }

        return subs // TODO: sort by priority
    },

    isSubscriptionValidFor(subscription, bookingSlot, activity, productsMap) {
        return (
            self.isSubscriptionValid(subscription, productsMap) && self.isSubscriptionCompatibleFor(subscription, bookingSlot, activity)
        );
    },

    getCardsValidSubscriptionsFor(cards, slot, activity, productsMap) {
        var subscriptions = []
        for (let card of cards) {
            for (let subscription of card.Subscriptions) {
                if (self.isSubscriptionValidFor(subscription, slot, activity, productsMap)) subscriptions.push(subscription)
            }
        }

        return subscriptions
    },

    cardsHasValidSubscriptionFor(cards, slot, activity, productsMap) {
        for (var card of cards) {
            for (var subscription of card.Subscriptions) {
                if (self.isSubscriptionValidFor(subscription, slot, activity, productsMap)) {
                    return true;
                }
            }
        }

        return false
    },

    subscriptionQuantity(sub, product) {
        product = sub.Product || product
        if (product && product.Subscription && product.Subscription.Type === constants.SUBSCRIPTION_TYPE_PASS_H) {
            if (sub.Quantity < 60) {
                return sub.Quantity + 'min'
            }
            const minutes = sub.Quantity % 60
            const hours = (sub.Quantity - minutes) / 60

            return hours + 'h' + (minutes < 10 ? '0' : '') + minutes + 'min'
        } else {
            return sub.Quantity
        }
    }
}

export default self