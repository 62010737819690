import Decimal from "decimal.js"
import { isNumeric, isPositive, isPositiveOrZero, isInteger, isValidEmail } from "./utils"

export default {
    requiredRule: v => !!v || 'Ce champ est requis',
    isIntegerRule: v => isInteger(v) || 'Cette valeur doit être entière',
    isPositiveRule: v => isPositive(v) || 'Cette valeur doit être supérieure à zéro',
    isIntegerOrNullRule: v => (!v || isInteger(v)) || 'Cette valeur doit être entière',
    isPositiveOrNullRule: v => (!v || isPositive(v)) || 'Cette valeur doit être supérieure à zéro',
    isPositiveOrZeroRule: v => isPositiveOrZero(v) || 'Cette valeur doit être supérieure ou égale à zéro',
    isNumericRule: v => isNumeric(v) || 'Cette valeur doit être numérique',
    isNumericOrNullRule: v => (!v || isNumeric(v)) || 'Cette valeur doit être numérique',
    isGreatherThanRule: c => v => parseFloat(v) > c || `Cette valeur doit être supérieure à ${new Decimal(c).toFixed(2)}`,
    isGreatherOrEqualRule: c => (v, decimals) => parseFloat(v) >= c || `Cette valeur doit être supérieure ou égale à ${new Decimal(c).toFixed(decimals || 2)}`,
    isLessThanRule: c => v => parseFloat(v) < c || `Cette valeur doit être inférieure à ${new Decimal(c).toFixed(2)}`,
    isLessOrEqualRule: c => v => parseFloat(v) <= c || `Cette valeur doit être inférieure ou égale à ${new Decimal(c).toFixed(2)}`,
    isEmailRule: v => isValidEmail(v) || "L'email doit être valide",
    isEmailOrNullRule: v => (!v || isValidEmail(v)) || "L'email doit être valide",
    isStringLongerThanRule: c => v => v.length > c || `Cette valeur doit contenir au moins ${c} caractères`,
}
