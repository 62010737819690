import ui, { templateTypes } from '../ui/templates'


export default {
    data: () => ({
        templateTypes
    }),
    methods: {
        ...ui,
    }
}