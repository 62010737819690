// no one JS import

const initialState = () => ({
    filter: {

        preSelectFilterSelectedName: 'Aucun',

        // Valeur du Group Radio Btn pour savoir quel type doit être utilisé (Mode SEASON, YEAR, MONTH, WEEK ou DAY)
        date_mode_choisi: 0,

        date_mode_annee_value: 0,
        date_mode_mois_value: 1,
        date_mode_semaine_value: 2,
        date_mode_jour_value: 3,
        date_mode_saison_value: 4,

        // mode SEASON
        mode_saison_annee: null,

        // mode YEAR
        mode_annee_du_annee: null,
        mode_annee_au_annee: null,

        // mode MONTH
        mode_mois_du_annee: null,
        mode_mois_au_annee: null,

        mode_mois_du_mois: '1',
        mode_mois_au_mois: '12',

        // mode WEEK
        mode_semaine_du_annee: null,
        mode_semaine_au_annee: null,

        mode_semaine_du_semaine: '1',
        mode_semaine_au_semaine: '52',

        /// //////////// Dates Picker :

        // mode DAY
        du_menu: false,
        du_picker: new Date().toISOString().substr(0, 10),

        au_menu: false,
        au_picker: new Date().toISOString().substr(0, 10),

        /// //////////// Animateurs Picker :

        selected_animateurs: [],

        /// //////////// Horaires Picker :

        selected_horaires: [],

        /// //////////// Activités Picker :

        selected_activites: [],

        /// //////////// Articles Picker :

        selected_articles: []
    }
})

export default {

    state: initialState,

    getters: {
        statsFilterResaFrequentations: state => state.filter
    },

    actions: {

        reset_stats_resa_frequentations (state) {
            const initial = initialState()
            Object.keys(initial).forEach(key => { state[key] = initial[key] })
        },

        /// //////////// Animateurs Picker : //////////////////////////////////////////////////////////////////////////////////////////

        getStatsSelectAllTrainers ({ commit }) {
            commit('set_selected_animateurs', [])
            this.state.stats.resa.filter.animateurs.forEach(function (anim) {
                commit('add_selected_animateurs', anim)
            })
        },

        /// //////////// Horaires Picker : //////////////////////////////////////////////////////////////////////////////////////////

        getStatsSelectAllTimetables ({ commit }) {
            commit('set_selected_horaires', [])
            this.state.stats.resa.filter.horaires.forEach(function (horaire) {
                commit('add_selected_horaires', horaire)
            })
        },

        /// //////////// Activités Picker : //////////////////////////////////////////////////////////////////////////////////////////

        getStatsSelectAllActivities ({ commit }) {
            commit('set_selected_activites', [])
            this.state.stats.resa.filter.activites.forEach(function (activite) {
                commit('add_selected_activites', activite)
            })
        },

        /// //////////// Articles Picker : //////////////////////////////////////////////////////////////////////////////////////////

        getStatsSelectAllProducts ({ commit }) {
            commit('set_selected_articles', [])
            this.state.stats.resa.filter.articles.forEach(function (article) {
                commit('add_selected_articles', article)
            })
        }

    },

    mutations: {
    /// //////////////////////////////////////////////////////////////////////
        set_statPreSelectFilterSelectedName (state, val) {
            state.filter.preSelectFilterSelectedName = val
        },
        /// //////////////////////////////////////////////////////////////////////
        /// //////////// Dates Picker :

        // Valeur du Group Radio Btn pour savoir quel type doit être utilisé (Mode SEASON, YEAR, MONTH, WEEK ou DAY)
        set_date_mode_choisi (state, val) {
            state.filter.planning_second_au_annee = val
        },

        set_date_mode_annee_value (state, val) {
            state.filter.date_mode_annee_value = val
        },
        set_date_mode_mois_value (state, val) {
            state.filter.date_mode_mois_value = val
        },
        set_date_mode_semaine_value (state, val) {
            state.filter.date_mode_semaine_value = val
        },
        set_date_mode_jour_value (state, val) {
            state.filter.date_mode_jour_value = val
        },
        set_date_mode_saison_value (state, val) {
            state.filter.date_mode_saison_value = val
        },

        // mode SEASON
        set_mode_saison_annee (state, val) {
            state.filter.mode_saison_annee = val
        },

        // mode YEAR
        set_mode_annee_du_annee (state, val) {
            state.filter.mode_annee_du_annee = val
        },
        set_mode_annee_au_annee (state, val) {
            state.filter.mode_annee_au_annee = val
        },

        // mode MONTH
        set_mode_mois_du_annee (state, val) {
            state.filter.mode_mois_du_annee = val
        },
        set_mode_mois_au_annee (state, val) {
            state.filter.mode_mois_au_annee = val
        },

        set_mode_mois_du_mois (state, val) {
            state.filter.mode_mois_du_mois = val
        },
        set_mode_mois_au_mois (state, val) {
            state.filter.mode_mois_au_mois = val
        },

        // mode WEEK
        set_mode_semaine_du_annee (state, val) {
            state.filter.mode_semaine_du_annee = val
        },
        set_mode_semaine_au_annee (state, val) {
            state.filter.mode_semaine_au_annee = val
        },

        set_mode_semaine_du_semaine (state, val) {
            state.filter.mode_semaine_du_semaine = val
        },
        set_mode_semaine_au_semaine (state, val) {
            state.filter.mode_semaine_au_semaine = val
        },

        /// //////////// Animateurs Picker :

        set_selected_animateurs (state, val) {
            state.filter.selected_animateurs = val
        },
        add_selected_animateurs (state, val) {
            state.filter.selected_animateurs.push(val)
        },

        /// //////////// Horaires Picker :

        set_selected_horaires (state, val) {
            state.filter.selected_horaires = val
        },
        add_selected_horaires (state, val) {
            state.filter.selected_horaires.push(val)
        },

        /// //////////// Activités Picker :

        set_selected_activites (state, val) {
            state.filter.selected_activites = val
        },
        add_selected_activites (state, val) {
            state.filter.selected_activites.push(val)
        },

        /// //////////// Articles Picker :

        set_selected_articles (state, val) {
            state.filter.selected_articles = val
        },
        add_selected_articles (state, val) {
            state.filter.selected_articles.push(val)
        }
    }
}
