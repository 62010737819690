export default [
    {
        path: '/activities',
        name: 'activities-index',
        meta: {
            title: 'Activités périodiques'
        },
        redirect: { name: 'sessions' },
        component: () => import('~/views/activities/Index.vue'),
        children: [
            {
                path: 'sessions',
                name: 'sessions',
                meta: {
                    title: 'Sessions périodiques'
                },
                component: () => import('~/views/activities/Sessions.vue')
            },
            {
                path: 'sessions/new',
                name: 'activities-sessions-new',
                meta: {
                    title: 'Création d\'une session'
                },
                component: () => import('~/components/sessions/show/SessionNew.vue')
            },
            {
                path: 'sessions/:id',
                name: 'activities-sessions-show',
                meta: {
                    title: 'Activités périodiques'
                },
                component: () => import('~/views/activities/Sessions.vue')
            },
            {
                path: 'registrations',
                name: 'registrations',
                meta: {
                    title: 'Inscriptions périodiques'
                },
                component: () => import('~/components/sessions/Registrations.vue')
            },
            {
                path: 'registrations/new',
                name: 'registrations-new',
                meta: {
                    title: 'Création d\'une inscription périodique'
                },
                component: () => import('~/components/sessions/RegistrationEdit.vue')
            },
            {
                path: 'registrations/:id',
                name: 'registrations-edit',
                meta: {
                    title: 'Modification d\'une inscription périodique'
                },
                component: () => import('~/components/sessions/RegistrationEdit.vue')
            },
            {
                path: 'phases',
                name: 'phases',
                meta: {
                    title: 'Phases d\'inscriptions'
                },
                component: () => import('~/views/activities/Phases.vue')
            },
            {
                path: 'phases/new',
                name: 'phases-new',
                meta: {
                    title: 'Création d\'une phase'
                },
                component: () => import('~/components/sessions/PhaseEdit.vue')
            },
            {
                path: 'phases/:id',
                name: 'phases-show',
                meta: {
                    title: 'Phases'
                },
                component: () => import('~/components/sessions/PhaseEdit.vue')
            },
            {
                path: 'cashregister/new',
                name: 'cashregister-new',
                meta: {
                    title: 'Création d\'une inscription périodique depuis la caisse',
                    disableDashboardLayout: true
                },
                component: () => import('~/components/sessions/RegistrationEdit.vue')
            }
        ]
    }
]
