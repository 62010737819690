export default [
    {
        path: '/planning',
        name: 'planning-index',
        meta: {
            title: 'Planning'
        },
        component: () => import('~/views/planning/Index.vue')
    }
]
