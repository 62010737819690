<template>
    <div>
        <tiptap-menu-bar
            :editor="editor"
            class="grey-border-bottom"
        />
        <editor-content
            :editor="editor"
            class="editor__content"
        />
    </div>
</template>

<script>
import TiptapMenuBar from '@/components/odeon/TiptapMenuBar'
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import Placeholder from '@tiptap/extension-placeholder'
import Dropcursor from '@tiptap/extension-dropcursor'
import { createImageExtension } from '@/plugins/ImageUpload.js'
import OdeonApi from '@/api/odeon'

export default {
    components: {
        TiptapMenuBar,
        EditorContent
    },

    props: {
        value: {
            type: String,
            required: false,
            default: () => ''
        },
        placeholder: {
            type: String,
            required: false,
            default: () => 'Votre message ici...'
        }
    },

    data () {
        return {
            editor: null
        }
    },

    mounted () {
        const self = this
        this.editor = new Editor({
            content: '',
            extensions: [
                StarterKit,
                Placeholder.configure({ placeholder: this.placeholder }),
                createImageExtension(this.upload),
                Image,
                // Highlight,
                Dropcursor
            ],
            onUpdate () {
                self.$emit('input', this.getHTML())
            }
        })
    },

    beforeDestroy () {
        this.editor.destroy()
    },

    methods: {
        clear () {
            this.editor.commands.clearContent()
        },

        async upload (image) {
            const res = await OdeonApi.postFile(image)
            return `/api/v1/odeon${res.data.URL}`
        }
    }

}
</script>
