// import Consts from '../consts'

import accessControl from "./access-control"
import bookings from "./bookings"

function padZero(str, len) {
    len = len || 2
    const zeros = new Array(len).join('0')
    return (zeros + str).slice(-len)
}

var self = {
    getColorForString(str) {
        let hash = 0
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash)
        }

        const c = (hash & 0x00FFFFFF)
            .toString(16)
            .toUpperCase()

        return '00000'.substring(0, 6 - c.length) + c
    },

    lightOrDark(color) {
        // Variables for red, green, blue values
        let r, g, b

        // Check the format of the color, HEX or RGB?
        if (color.match(/^rgb/)) {
            // If HEX --> store the red, green, blue values in separate variables
            color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)

            r = color[1]
            g = color[2]
            b = color[3]
        } else {
            // If RGB --> Convert it to HEX: http://gist.github.com/983661
            color = +('0x' + color.slice(1).replace(
                color.length < 5 && /./g, '$&$&'))

            r = color >> 16
            g = color >> 8 & 255
            b = color & 255
        }

        // HSP (Highly Sensitive Pool) equation from http://alienryderflex.com/hsp.html
        const hsp = Math.sqrt(
            0.299 * (r * r) +
            0.587 * (g * g) +
            0.114 * (b * b)
        )

        // Using the HSP value, determine whether the color is light or dark
        if (hsp > 147.5) {
            return 'light'
        } else {
            return 'dark'
        }
    },

    invertColor(hex) {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1)
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
        }
        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.')
        }
        // invert color components
        const r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16)
        const g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16)
        const b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16)
        // pad each with zeros and return
        return '#' + padZero(r) + padZero(g) + padZero(b)
    },

    HexToHSL(H) {
        // Convert hex to RGB first
        let r = 0; let g = 0; let b = 0
        if (H.length === 4) {
            r = '0x' + H[1] + H[1]
            g = '0x' + H[2] + H[2]
            b = '0x' + H[3] + H[3]
        } else if (H.length === 7) {
            r = '0x' + H[1] + H[2]
            g = '0x' + H[3] + H[4]
            b = '0x' + H[5] + H[6]
        }
        // Then to HSL
        r /= 255
        g /= 255
        b /= 255
        const cmin = Math.min(r, g, b)
        const cmax = Math.max(r, g, b)
        const delta = cmax - cmin
        let h = 0
        let s = 0
        let l = 0

        if (delta === 0) { h = 0 } else if (cmax === r) { h = ((g - b) / delta) % 6 } else if (cmax === g) { h = (b - r) / delta + 2 } else { h = (r - g) / delta + 4 }

        h = Math.round(h * 60)

        if (h < 0) { h += 360 }

        l = (cmax + cmin) / 2
        s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
        s = +(s * 100).toFixed(1)
        l = +(l * 100).toFixed(1)

        return { h, s, l }
    },

    changeColorLum(hex, lum) {

        // validate hex string
        hex = String(hex).replace(/[^0-9a-f]/gi, '');
        if (hex.length < 6) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }

        lum = lum || 0;

        // convert to decimal and change luminosity
        var rgb = "#", c, i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i * 2, 2), 16);
            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
            rgb += ("00" + c).substr(c.length);
        }

        return rgb;
    },

    pastelizeColor(H) {
        let { h, s, l } = this.HexToHSL(H)

        s = 100
        l = 87.5

        return this.HSLToHex(h, s, l)
    },

    darkenizeColor(H) {
        let { h, s, l } = this.HexToHSL(H)

        s = 100
        l = 30.5

        return this.HSLToHex(h, s, l)
    },

    HSLToHex(h, s, l) {
        s /= 100
        l /= 100

        const c = (1 - Math.abs(2 * l - 1)) * s
        const x = c * (1 - Math.abs((h / 60) % 2 - 1))
        const m = l - c / 2
        let r = 0
        let g = 0
        let b = 0

        if (h >= 0 && h < 60) {
            r = c
            g = x
            b = 0
        } else if (h >= 60 && h < 120) {
            r = x
            g = c
            b = 0
        } else if (h >= 120 && h < 180) {
            r = 0
            g = c
            b = x
        } else if (h >= 180 && h < 240) {
            r = 0
            g = x
            b = c
        } else if (h >= 240 && h < 300) {
            r = x
            g = 0
            b = c
        } else if (h >= 300 && h < 360) {
            r = c
            g = 0
            b = x
        }
        // Having obtained RGB, convert channels to hex
        r = Math.round((r + m) * 255).toString(16)
        g = Math.round((g + m) * 255).toString(16)
        b = Math.round((b + m) * 255).toString(16)

        // Prepend 0s, if necessary
        if (r.length === 1) { r = '0' + r }
        if (g.length === 1) { g = '0' + g }
        if (b.length === 1) { b = '0' + b }

        return '#' + r + g + b
    },


    HexToRGBA(hexCode, opacity) {
        var hex = hexCode.replace('#', '');

        if (hex.length === 3) {
            hex += hex
        }

        var r = parseInt(hex.substring(0, 2), 16),
            g = parseInt(hex.substring(2, 4), 16),
            b = parseInt(hex.substring(4, 6), 16);

        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    },

    /**
    * @deprecated Please use ui/access-control/turnstilesState.color instead
    */
    turnstilesStateColor(state, parentState) {
        return accessControl.turnstilesState(state, parentState).color
    },

    /**
    * @deprecated Please use ui/bookings/bookingsStatus.color instead
    */
    bookingStatusColor(x) {
        return bookings.bookingStatus(x).color
    },

    parseColor (color) {
        const x = document.createElement('div')
        document.body.appendChild(x)
        let rgba
        let red = 0
        let green = 0
        let blue = 0
        let alpha = 0
        try {
            x.style = 'color: ' + color + '!important'
            color = window.getComputedStyle(x).color
            rgba = color
                .match(/rgba?\((.*)\)/)[1]
                .split(',')
                .map(Number)
            red = rgba[0]
            green = rgba[1]
            blue = rgba[2]
            alpha = '3' in rgba ? rgba[3] : 1
        } catch (e) {
            console.error(e)
        }
        x.parentNode.removeChild(x)
        return { r: red, g: green, b: blue, a: alpha }
    },
}

export default self;