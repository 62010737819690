<template>
    <v-card>
        <v-window v-model="slide" continuous>
            <v-window-item :value="1">
                <v-list max-height="80vh" class="overflow-y-auto">
                    <v-list-item @click="goToDocs" :disabled="slide === 2">
                        <v-list-item-avatar>
                            <v-progress-circular indeterminate v-if="bookstackLoading" />
                            <v-icon v-else> mdi-book-open-page-variant-outline </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>Accéder à la documentation</v-list-item-title>
                            <v-list-item-subtitle>
                                Besoin d'aide, d'explications ? Notre documentation en ligne est
                                disponible à tout moment.
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon> mdi-open-in-new </v-icon>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="goToOdeon" :disabled="slide === 2">
                        <v-list-item-avatar>
                            <v-progress-circular indeterminate v-if="odeonLoading" />
                            <v-icon v-else> mdi-web </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>Accéder à Odéon</v-list-item-title>
                            <v-list-item-subtitle>
                                Accéder directement à Odéon, notre solution de support.
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon> mdi-open-in-new </v-icon>
                        </v-list-item-action>
                    </v-list-item>
                    <div v-if="modules.odeon">
                        <v-list-item
                            @click="
                                slide = 2
                                slideSecondType = 'new-ticket'
                            "

                            :disabled="slide === 2"
                        >
                            <v-list-item-avatar>
                                <v-icon> mdi-chat-processing </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title
                                >Créer une nouvelle demande (ticket)</v-list-item-title
                                >
                                <v-list-item-subtitle>
                                    Notre équipe reviendra vers vous rapidement, et vous pourrez
                                    suivre la progression de votre demande.
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon> mdi-arrow-right </v-icon>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider />
                        <v-subheader>Mes derniers tickets</v-subheader>
                        <v-alert
                            type="info"
                            class="mx-4"
                            outlined
                            v-if="filteredTickets.length === 0"
                        >Aucun ticket ouvert récemment</v-alert
                        >
                        <v-list-item
                            v-for="t in filteredTickets"
                            :key="t.ID"
                            :disabled="slide === 2"
                            @click="openTicket(t)"
                        >
                            <v-list-item-avatar>
                                <v-icon :color="getTicketStatus(t.Status).color"
                                >mdi-{{ getTicketStatus(t.Status).icon }}</v-icon
                                >
                            </v-list-item-avatar>
                            <v-list-item-content
                                :class="{ 'font-weight-bold': ticketWasUpdated(t) }"
                            >
                                <v-list-item-title
                                >Ticket #{{ t.Hash }} -
                                    <span v-html="stripTags(t.InitialMessage)"></span
                                    ></v-list-item-title>
                                <v-list-item-subtitle
                                >{{ getTicketStatus(t.Status).title }} - Dernière mise à jour
                                    le
                                    {{ formatDateTimeHuman(t.LastUpdate) }}</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                    <v-alert v-else outlined type="info" class="mx-2 mt-3 mb-1">
                        Le système de ticket est désactivé. Pour l'activer, demandez nous !
                    </v-alert>
                </v-list>
            </v-window-item>

            <v-window-item :value="2">
                <template v-if="slideSecondType === 'new-ticket'">
                    <v-card-title> Nouveau ticket </v-card-title>

                    <v-card-text>
                        Veuillez saisir votre demande ci-dessous. Afin d'assurer un
                        traitement efficace, essayez de répondre aux questions suivantes :
                        <ul>
                            <li>Quel est le problème rencontré ?</li>
                            <li>Qu'avez-vous essayé de faire ?</li>
                            <li>
                                Quel est le résultat obtenu, quel est le résultat attendu ?
                            </li>
                            <li>Donnez idéalement un exemple.</li>
                        </ul>
                        <br />

                        <v-form ref="newTicketForm">
                            <v-select
                                v-model="newTicket.Category"
                                filled
                                label="Catégorie de la demande"
                                :rules="[(v) => !!v || 'Veuillez catégoriser la demande']"
                                :items="categories"
                            />
                        </v-form>
                    </v-card-text>

                    <div class="grey-border-top full-width flex-fill">
                        <tiptap-editor ref="editor" v-model="newTicket.InitialMessage" />

                        <v-divider />

                        <div class="mx-3 my-2">
                            <div layout="row" layout-align="start center" class="mb-2">
                                <span class="mr-3 text-subtitle-2">Fichiers à joindre</span>

                                <v-tooltip fixed bottom>
                                    <template v-slot:activator="{on}">
                                        <v-btn icon small color="primary" v-on="on" @click="addAttachement">
                                            <v-icon small>mdi-attachment-plus</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Joindre un fichier</span>
                                </v-tooltip>
                                <input
                                    ref="fileUpload"
                                    hidden
                                    type="file"
                                    @change="onFileSelected"
                                >
                            </div>
                            <div v-if="attachments && attachments.length">
                                <ul style="list-style-type: none;margin: 0;padding: 0;" layout="row" layout-align="start center"
                                    v-for="(a, idx) in attachments"  :key="idx" >
                                    <li>
                                        <v-icon v-if="a.type === 'application/pdf'">mdi-file-pdf-box</v-icon>
                                        <v-icon v-else-if="a.type.startsWith('text/')">mdi-text-box-outline</v-icon>
                                        <v-icon v-else-if="a.type.startsWith('image/')">mdi-image</v-icon>
                                        <v-icon v-else-if="a.type.startsWith('video/')">mdi-video</v-icon>
                                        <v-icon v-else>mdi-file-outline</v-icon>
                                    </li>
                                    <span class="mx-3"> {{ a.name }}</span>
                                    <v-btn icon color="red" @click="deleteAttachment(idx)"><v-icon>mdi-close</v-icon></v-btn>
                                </ul>
                            </div>
                            <div v-else>Aucun fichier joint</div>
                        </div>
                        <v-divider class="mb-3" />
                    </div>

                    <v-card-actions layout="row" layout-align="space-between center">
                        <v-btn text @click="closeNewTicket">
                            <v-icon left>mdi-arrow-left</v-icon>
                            Annuler
                        </v-btn>

                        <v-btn
                            text
                            color="green"
                            @click="send"
                            :loading="isTicketSending"
                            :disabled="!stripTags(newTicket.InitialMessage.trim())"
                        >
                            <v-icon left>mdi-send</v-icon>
                            Envoyer la demande
                        </v-btn>
                    </v-card-actions>
                </template>
                <template v-else>
                    <div v-if="ticket">
                        <ticket-details-panel
                            :active-ticket="ticket"
                            customer
                            @back="slide = 1"
                        />
                    </div>
                    <v-progress-circular indeterminate v-else />
                </template>
            </v-window-item>
        </v-window>
    </v-card>
</template>

<script>
import OdeonApi from '@/api/odeon'
import _ from 'lodash'
import TicketDetailsPanel from '@/components/odeon/TicketDetailsPanel'
import moment from 'moment'
import WebviewMixin from '~/mixins/webview'
import OdeonMixin from 'neptune/mixins/odeon'
import TiptapEditor from '@/components/odeon/TiptapEditor'
import { mapGetters } from 'vuex'
import Consts from 'neptune/consts'
import utils from 'neptune/helpers/utils'
import eventbus from '@/eventbus'

export default {
    name: 'OdeonPopup',
    mixins: [WebviewMixin, OdeonMixin],
    components: { TicketDetailsPanel, TiptapEditor },
    data (vm) {
        return {
            Consts,
            visible: false,
            slide: 1,
            slideSecondType: '',
            categories: [
                { text: "Contrôle d'accès", value: "Contrôle d'accès" },
                { text: 'Caisse', value: 'Caisse' },
                { text: 'Réservation (Dims)', value: 'Réservation (Dims)' },
                { text: 'Site Internet', value: 'Site Internet' },
                { text: 'Statistiques', value: 'Statistiques' },
                { text: 'Spa', value: 'Spa' },
                { text: 'Autre', value: 'Autre' }
            ],
            newTicket: {
                Category: vm.wvEnabled() ? 'Caisse' : null,
                InitialMessage: ''
            },
            isTicketSending: false,
            tickets: [],
            ticket: null,
            ticketMessages: null,
            odeonUserID: null,
            odeonHost: null,
            bookstackLoading: false,
            odeonLoading: false,
            attachments: []
        }
    },

    computed: {
        ...mapGetters(['modules']),
        ticketStatusById () {
            return _.keyBy(Consts.TICKET_STATUS, 'id')
        },

        filteredTickets () {
            return this.tickets
                .filter((ticket) => {
                    return (
                        ticket.Status !== 'resolved' ||
                        moment().diff(ticket.LastUpdate, 'days') < 7
                    )
                })
                .sort((a, b) => {
                    return moment(a.LastUpdate).isAfter(moment(b.LastUpdate)) ? -1 : 1
                })
        }
    },

    mounted () {
        this.$store.dispatch('getModules')
        if (this.modules.odeon) {
            OdeonApi.getOdeonParams()
                .then((res) => {
                    this.odeonUserID = res.data.UserID
                    this.odeonHost = res.data.Host
                    this.$nextTick(this.fetchTickets)
                })
                .catch((err) => {
                    console.error(err)
                })

            eventbus.$on('odeon-ticket-updated', this.handleTicketUpdated)
            eventbus.$on('odeon-new-ticket', this.fetchTickets)
        }
    },

    beforeDestroy () {
        eventbus.$off('odeon-ticket-updated', this.handleTicketUpdated)
        eventbus.$off('odeon-new-ticket', this.fetchTickets)
    },

    methods: {
        goToDocs () {
            this.bookstackLoading = true
            OdeonApi.getBookstackToken().then(
                (res) => {
                    window.open(
                        'https://docs.streamlor.io/autologin.php?bookstack_session=' +
                            res.data.Cookie
                    )
                    this.bookstackLoading = false
                },
                () => {
                    // Fallback to the regular login
                    window.open('https://docs.streamlor.io/')
                    this.bookstackLoading = false
                }
            )
        },

        goToOdeon () {
            this.odeonLoading = true
            OdeonApi.getOdeonAuthToken({ UserID: this.odeonUserID })
                .then((res) => {
                    window.open(`${this.odeonHost}?token=${res.data.Token}`)
                })
                .catch(() => {
                    this.$snotify.error('Une erreur est survenue, veuillez réessayer')
                })
                .finally(() => {
                    this.odeonLoading = false
                })
        },

        handleTicketUpdated (ticket) {
            if (this.ticket && this.ticket.ID === ticket.ID) {
                this.ticket = ticket
            }

            this.fetchTickets()
        },

        ticketWasUpdated (ticket) {
            if (!ticket.LastSeen) {
                return false
            }

            const userLastSeen = ticket.LastSeen.find(
                (s) => s.UserID === this.odeonUserID
            )
            if (!userLastSeen) {
                return true
            }

            return moment(userLastSeen.Seen).isBefore(moment(ticket.LastUpdate))
        },

        fetchTickets () {
            OdeonApi.getTickets().then((res) => {
                this.tickets = res.data
                let numUnread = 0
                for (let i = 0; i < this.tickets.length; ++i) {
                    if (!this.tickets[i].IsPrivate && this.ticketWasUpdated(this.tickets[i])) {
                        numUnread++
                    }
                }
                this.$emit('unread', numUnread)
                if (this.wvEnabled()) {
                    // can't do that for webview, for wathever rease, $emit is not working unless the component is visible
                    this.wvOdeonUnreadAmount(numUnread)
                }
            }).catch(() => {
                console.warn('Failed to get Odeon tickets')
            })
        },

        stripTags (input) {
            return input.replace(/<\/?[^>]+(>|$)/g, '')
        },

        send () {
            if (
                !this.$refs.newTicketForm.validate() ||
                this.stripTags(this.newTicket.InitialMessage.trim()) === ''
            ) {
                this.$snotify.error('Veuillez contrôler les valeurs saisies')
                return
            }
            this.isTicketSending = true
            this.newTicket.InitialMessage = this.newTicket.InitialMessage.replace(
                'api/v1/odeon/',
                ''
            )

            const relevantData = []
            if (this.wvEnabled()) {
                const currentSeller = this.wvGetDataValue('seller')
                if (currentSeller) {
                    relevantData.push({
                        Type: 'seller',
                        ID: currentSeller.ID,
                        Label: `Vendeur: ${currentSeller.Firstname}`
                    })
                }

                const currentCashregister = this.wvGetDataValue('cashregister')
                if (currentCashregister) {
                    relevantData.push({
                        Type: 'cashregister',
                        ID: currentCashregister.ID,
                        Label: `${currentCashregister.Name} (${currentCashregister.Version})`
                    })
                }

                const currentEstablishment = this.wvGetDataValue('establishment')
                if (currentEstablishment) {
                    relevantData.push({
                        Type: 'establishment',
                        ID: currentEstablishment.ID,
                        Label: `${currentEstablishment.Label}`
                    })
                }

                const currentClient = this.wvGetDataValue('currentClient')
                if (currentClient) {
                    relevantData.push({
                        Type: 'client',
                        ID: currentClient.ID,
                        Label: `Client: ${currentClient.Firstname} ${currentClient.Lastname}`
                    })
                }

                const currentSale = this.wvGetDataValue('currentSale')
                if (currentSale) {
                    relevantData.push({
                        Type: 'sale',
                        ID: currentSale.ID,
                        Label: `Vente de ${currentSale.Firstname} ${
                            currentSale.Lastname
                        } le ${this.formatDate(
                            currentSale.BeginDate,
                            'DD/MM/YYYY'
                        )} à ${this.formatDate(currentSale.BeginDate, 'HH:mm')}`
                    })
                }
            }

            OdeonApi.createTicket({
                InitialMessage:
                    '[' +
                    this.newTicket.Category +
                    '] - ' +
                    this.newTicket.InitialMessage,
                RelevantData: relevantData
            }).then((ticketRes) => {
                this.closeNewTicket()
                this.fetchTickets()

                const screenRecording = this.wvGetDataValue('screenRecording')
                if (screenRecording) {
                    // send ticket attachment
                    // create blob from data url
                    const blob = utils.dataURLToBlob(screenRecording)
                    const upload = new File([blob], 'screenRecording.webm', { type: blob.type })

                    OdeonApi.postFile(upload).then(uploadRes => {
                        OdeonApi.createTicketAttachment({
                            AttachmentID: uploadRes.data.Identifier,
                            TicketID: ticketRes.data.ID,
                            Private: true,
                            MimeType: upload.type
                        }).catch(err => {
                            console.error(err)
                        })
                    }).catch(err => {
                        console.error(err)
                    })
                }

                if (this.attachments && this.attachments.length) {
                    for (const file of this.attachments) {
                        OdeonApi.postFile(file).then(uploadRes => {
                            OdeonApi.createTicketAttachment({
                                Filename: file.name,
                                AttachmentID: uploadRes.data.Identifier,
                                TicketID: ticketRes.data.ID,
                                MimeType: file.type
                            }).catch(err => {
                                console.error(err)
                            })
                        }).catch(err => {
                            console.error(err)
                        }).finally(() => { this.attachments = [] })
                    }
                }
            }, () => {
                this.$snotify.error('Une erreur est survenue, veuillez réessayer')
            }).finally(() => {
                this.isTicketSending = false
            })
        },

        closeNewTicket () {
            this.slide = 1
            this.newTicket.Category = null
            this.$refs.newTicketForm.reset()
            this.$refs.editor.clear()
        },

        openTicket (t) {
            this.ticket = null
            this.ticketMessages = null
            OdeonApi.getTicket(t.ID).then((res) => {
                this.ticket = res.data
                this.slide = 2
                this.slideSecondType = 'view-ticket'
            })
        },

        addAttachement () {
            this.$refs.fileUpload.click()
        },

        deleteAttachment (index) {
            this.attachments.splice(index, 1)
        },

        onFileSelected () {
            this.attachments.push(this.$refs.fileUpload.files[0])
        }
    }
}
</script>
<style lang="scss">
/* Basic editor styles */
.editor {
  outline: none;
  display: flex;
  flex-direction: column;
  max-height: 400px;
  color: #0d0d0d;
  background-color: white;
  border: 3px solid #0d0d0d;
  border-radius: 0.75rem;

  &__header {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    flex-wrap: wrap;
    padding: 0.25rem;
    border-bottom: 3px solid #0d0d0d;
  }

  &__content {
    padding: 1.25rem 1rem;
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    max-height: 15vh;
  }
}

.ProseMirror {
  outline: none;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(97, 97, 97, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  mark {
    background-color: #faf594;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  hr {
    margin: 1rem 0;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(13, 13, 13, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(13, 13, 13, 0.1);
    margin: 2rem 0;
  }

  ul[data-type='taskList'] {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
      }
    }
  }
}
/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #ced4da;
  pointer-events: none;
  height: 0;
}
</style>
<style lang="scss" scoped>
.info-timer-block {
  min-width: 170px;
  width: 170px;
  max-width: 170px;
}

.single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

$font-family: 'Open Sans';
$grey-border-color: rgba(0, 0, 0, 0.12);

.v-application {
  [class*='text-'] {
    font-family: $font-family, sans-serif !important;
  }
  .v-btn {
    font-weight: 600;
  }
  font-family: $font-family, sans-serif !important;

  .font-weight-light {
    font-weight: 300 !important;
  }
  .font-weight-medium {
    font-weight: 600 !important;
  }
  .font-weight-bold {
    font-weight: 700 !important;
  }
  .font-weight-black {
    font-weight: 800 !important;
  }
}

.full-height {
  height: 100%;
}

.grey-border-right {
  border-right: 1px solid $grey-border-color;
}
.grey-border-left {
  border-left: 1px solid $grey-border-color;
}
.grey-border-top {
  border-top: 1px solid $grey-border-color;
}
.grey-border-bottom {
  border-bottom: 1px solid $grey-border-color;
}

.pointer {
  cursor: pointer;
}
</style>
