import helpers from '../helpers/lockers'
import {
    lockersStates, 
    lockersStatuses, 
    lockersAccessModes, 
    lockersBookingModes, 
    lockersIncidentsTypes, 
    lockersBookingStates, 
    lockersEventTypes, 
    ledOptionsTypes, 
    daysTypes 
} from '../ui/lockers'


export default {
    data: () => ({
        lockersStates,
        lockersStatuses,
        lockersAccessModes,
        lockersBookingModes,
        lockersIncidentsTypes,
        lockersBookingStates,
        lockersEventTypes,
        ledOptionsTypes,
        daysTypes
    }),
    methods: {
        ...helpers
    }
}