<template>
    <div class="d-flex flex-row flex-wrap">
        <template v-for="(item, index) in items">
            <div
                v-if="item.type === 'divider'"
                :key="index"
                class="divider"
            />
            <menu-item
                v-else
                :key="index"
                v-bind="item"
            />
        </template>
        <input
            ref="imageUpload"
            hidden
            type="file"
            @change="onFileSelected"
        >
    </div>
</template>

<script>
import MenuItem from './TiptapMenuItem.vue'
import OdeonApi from '@/api/odeon'

export default {
    components: {
        MenuItem
    },

    props: {
        editor: {
            type: Object,
            required: false,
            default () { return null }
        }
    },

    data () {
        return {
            items: [
                {
                    icon: 'format-bold',
                    title: 'Gras',
                    action: () => this.editor.chain().focus().toggleBold().run(),
                    isActive: () => this.editor && this.editor.isActive('bold')
                },
                {
                    icon: 'format-italic',
                    title: 'Italique',
                    action: () => this.editor.chain().focus().toggleItalic().run(),
                    isActive: () => this.editor && this.editor.isActive('italic')
                },
                {
                    icon: 'format-strikethrough',
                    title: 'Barré',
                    action: () => this.editor.chain().focus().toggleStrike().run(),
                    isActive: () => this.editor && this.editor.isActive('strike')
                },
                {
                    icon: 'code-tags',
                    title: 'Code / pré-formatté',
                    action: () => this.editor.chain().focus().toggleCode().run(),
                    isActive: () => this.editor && this.editor.isActive('code')
                },
                {
                    icon: 'format-color-highlight',
                    title: 'Highlight',
                    action: () => this.editor.chain().focus().toggleHighlight().run(),
                    isActive: () => this.editor && this.editor.isActive('highlight')
                },
                {
                    type: 'divider'
                },
                {
                    icon: 'format-list-bulleted',
                    title: 'Bullet List',
                    action: () => this.editor.chain().focus().toggleBulletList().run(),
                    isActive: () => this.editor && this.editor.isActive('bulletList')
                },
                {
                    icon: 'format-list-numbered',
                    title: 'Ordered List',
                    action: () => this.editor.chain().focus().toggleOrderedList().run(),
                    isActive: () => this.editor && this.editor.isActive('orderedList')
                },
                {
                    icon: 'code-braces-box',
                    title: 'Code Block',
                    action: () => this.editor.chain().focus().toggleCodeBlock().run(),
                    isActive: () => this.editor && this.editor.isActive('codeBlock')
                },
                {
                    type: 'divider'
                },
                {
                    icon: 'image',
                    title: 'Image',
                    action: () => this.addImage()
                },
                {
                    icon: 'format-clear',
                    title: 'Clear Format',
                    action: () => this.editor.chain()
                        .focus()
                        .clearNodes()
                        .unsetAllMarks()
                        .run()
                },
                {
                    type: 'divider'
                },
                {
                    icon: 'undo',
                    title: 'Undo',
                    action: () => this.editor.chain().focus().undo().run()
                },
                {
                    icon: 'redo',
                    title: 'Redo',
                    action: () => this.editor.chain().focus().redo().run()
                }
            ]
        }
    },

    methods: {
        addImage () {
            this.$refs.imageUpload.click()
        },

        onFileSelected () {
            const file = this.$refs.imageUpload.files[0]
            return OdeonApi.postFile(file).then(res => {
                this.editor.chain().focus().setImage({ src: `/api/v1/odeon${res.data.URL}` }).run()
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.divider {
    width: 2px;
    height: 1.25rem;
    background-color: rgba(0, 0, 0, 0.1);
    margin-left: 0.5rem;
    margin-right: 0.75rem;
}
</style>
