import constants from './../consts'

const templateTypes = [
    { value: constants.TEMPLATE_TYPES.TICKET, selectedTemplateParam: constants.GLOBAL_PARAMS.PARAM_TICKET_TEMPLATE, label: "Ticket" },
    { value: constants.TEMPLATE_TYPES.BILL, selectedTemplateParam: constants.GLOBAL_PARAMS.PARAM_BILL_TEMPLATE, label: "Facture" },
    { value: constants.TEMPLATE_TYPES.ACCESS_CONTROL_VOUCHER, selectedTemplateParam: constants.GLOBAL_PARAMS.PARAM_ACCESS_CONTROL_VOUCHER_TEMPLATE, label: "Bon d'accès" },
    { value: constants.TEMPLATE_TYPES.VOUCHER_DISCOUNT, selectedTemplateParam: constants.GLOBAL_PARAMS.PARAM_VOUCHER_DISCOUNT_TEMPLATE, label: "Code promotionnel" },
    { value: constants.TEMPLATE_TYPES.WEB_ACCESS_CONTROL_VOUCHER, selectedTemplateParam: constants.GLOBAL_PARAMS.PARAM_WEB_ACCESS_CONTROL_VOUCHER_TEMPLATE, label: "Bon d'accès web" },
    { value: constants.TEMPLATE_TYPES.VOUCHER, selectedTemplateParam: constants.GLOBAL_PARAMS.PARAM_VOUCHER_TEMPLATE, label: "Bon d'achat" },
    { value: constants.TEMPLATE_TYPES.VOUCHER_GIFT_CARD, selectedTemplateParam: constants.GLOBAL_PARAMS.PARAM_VOUCHER_GIFT_CARD_TEMPLATE, label: "Carte cadeau" },
    { value: constants.TEMPLATE_TYPES.VOUCHER_WEB_GIFT_CARD, selectedTemplateParam: constants.GLOBAL_PARAMS.PARAM_VOUCHER_WEB_GIFT_CARD_TEMPLATE, label: "Carte cadeau web" },
    { value: constants.TEMPLATE_TYPES.VOUCHER_COLLECTABLE, selectedTemplateParam: constants.GLOBAL_PARAMS.PARAM_COLLECTABLE_VOUCHER_TEMPLATE, label: "Bon acheté en ligne à récupérer"},
]

export default {
    templateType(x) {
        return templateTypes.find(t => t.value == x)
    }
}

export { templateTypes }