export default [
    {
        path: '/turnstiles',
        name: 'turnstiles-index',
        meta: {
            title: 'Tourniquets'
        },
        redirect: { name: 'turnstiles-status' },
        component: () => import('~/views/turnstiles/Index.vue'),
        children: [
            {
                path: 'status',
                name: 'turnstiles-status',
                meta: {
                    title: 'État des terminaux'
                },
                component: () => import('~/views/turnstiles/Status.vue')
            },
            {
                path: 'hardware',
                name: 'turnstiles-hardware',
                meta: {
                    title: 'Configuration du matériel'
                },
                component: () => import('~/views/turnstiles/Hardware.vue')
            },
            {
                path: 'planning',
                name: 'turnstiles-planning',
                meta: {
                    title: 'Planning d\'accès'
                },
                component: () => import('~/views/turnstiles/PlanningAccess.vue')
            },
            {
                path: 'area-configuration',
                name: 'turnstiles-area-configuration',
                meta: {
                    title: 'Administration des zones'
                },
                component: () => import('~/views/turnstiles/ZoneAdministration.vue'),
                children: [
                    {
                        path: ':id',
                        name: 'planning-area-details',
                        meta: {
                            title: 'Détail de la zone'
                        },
                        props: true,
                        component: () => import('@/views/turnstiles/ZoneConfiguration.vue')
                    }
                ]
            },
            {
                path: 'templates',
                name: 'timeslots-templates',
                meta: {
                    title: 'Modèles de créneaux'
                },
                component: () => import('~/views/turnstiles/timeslotsTemplates.vue')
            },
            {
                path: 'weeks',
                name: 'turnstiles-weeks',
                meta: {
                    title: 'Types de semaines'
                },
                component: () => import('~/views/turnstiles/Weeks.vue')
            },
            {
                path: 'scan',
                name: 'turnstiles-scan',
                meta: {
                    title: 'Scan de billets'
                },
                component: () => import('~/views/turnstiles/Scan.vue')
            },
            {
                path: 'history',
                name: 'turnstiles-history',
                meta: {
                    title: 'Historique de passage'
                },
                component: () => import('~/views/turnstiles/History.vue')
            },
            {
                path: 'penalties',
                name: 'turnstiles-penalties',
                meta: {
                    title: 'Pénalités'
                },
                component: () => import('~/views/turnstiles/Penalties.vue')
            }
        ]
    }
]
