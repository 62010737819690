const initialState = () => ({
    locationsZoneTree: [],
    selectedZoneTreeLocation: []
})

export default {
    state: initialState,

    getters: {
        locationsZoneTree: state => state.locationsZoneTree,
        selectedZoneTreeLocation: state => state.selectedZoneTreeLocation
    },

    mutations: {
        set_locations_zone_tree (state, tree) {
            state.locationsZoneTree = tree
        },
        set_selected_zone_tree_location (state, location) {
            state.selectedZoneTreeLocation = location
        }
    }
}
