export default [
  {
    path: '/quotation',
    name: 'quotation-index',
    meta: {
      title: 'Gestion des comptes pro'
    },
    redirect: { name: 'quotation-quotations' },
    component: () => import('~/views/quotation/Index.vue'),
    children: [
      {
        path: 'accounts',
        name: 'quotation-accounts',
        meta: {
          title: 'Liste des comptes'
        },
        component: () => import('~/views/quotation/Accounts.vue')
      },
      {
        path: 'accounts/:id',
        name: 'quotation-accounts-show',
        meta: {
          title: 'Compte'
        },
        component: () => import('~/views/quotation/AccountsShow.vue')
      },
      {
        path: 'requests',
        name: 'quotation-requests',
        meta: {
          title: 'Liste des demandes'
        },
        component: () => import('~/views/quotation/Requests.vue')
      },
      {
        path: 'requests/:id',
        name: 'quotation-requests-show',
        meta: {
          title: 'Demande'
        },
        component: () => import('~/views/quotation/RequestShow.vue')
      },
      {
        path: 'quotations',
        name: 'quotation-quotations',
        meta: {
          title: 'Liste des devis'
        },
        component: () => import('~/views/quotation/Quotations.vue')
      },
      {
        path: 'quotations/new',
        name: 'quotation-quotations-new',
        meta: {
          title: "Création d'un devis"
        },
        component: () => import('~/views/quotation/QuotationEdit.vue')
      },
      {
        path: 'quotations/:id',
        name: 'quotations-show',
        meta: {
          title: 'Devis'
        },
        component: () => import('~/views/quotation/QuotationEdit.vue')
      },
      {
        path: 'orders',
        name: 'quotation-orders',
        meta: {
          title: 'Liste des bons de commande'
        },
        component: () => import('~/views/quotation/Orders.vue')
      },
      {
        path: 'orders/:id',
        name: 'quotation-orders-show',
        meta: {
          title: 'Bon de commande'
        },
        component: () => import('~/views/quotation/OrderShow.vue')
      },
      {
        path: 'settings',
        name: 'quotation-settings',
        meta: {
          title: 'Paramètres'
        },
        component: () => import('~/views/quotation/Settings.vue'),
        redirect: { name: 'quotation-settings-families' },
        children: [
          {
            path: 'families',
            name: 'quotation-settings-families',
            meta: {
              title: 'Familles de produits'
            },
            component: () =>
              import('~/components/quotation/ProductFamiliesList.vue')
          },
          {
            path: 'pricings',
            name: 'quotation-settings-pricings',
            meta: {
              title: 'Tarifs'
            },
            component: () => import('~/components/quotation/PricingList.vue')
          },
          {
            path: 'products',
            name: 'quotation-settings-products',
            meta: {
              title: 'Produits'
            },
            component: () => import('~/components/quotation/ProductList.vue')
          },
          {
            path: 'catalogs',
            name: 'quotation-settings-catalogs',
            meta: {
              title: 'Catalogues clients'
            },
            component: () => import('~/components/quotation/CatalogList.vue')
          },
          {
            path: 'settings',
            name: 'quotation-settings-settings',
            meta: {
              title: 'Configuration'
            },
            component: () =>
              import('~/components/quotation/QuotationsSettings.vue')
          }
        ]
      }
    ]
  }
]
