<template>
    <div
        v-if="activeTicket !== null"
        :class="{'full-height': true, 'd-flex': true, 'flex-column': true}"
    >
        <v-toolbar color="blue-grey lighten-4">
            <v-btn icon left @click="back">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title v-if="activeTicket" v-html="getShortTitle(stripTags(activeTicket.InitialMessage))"></v-toolbar-title>
            <v-skeleton-loader
                v-else
                type="heading"
            />
            <v-spacer></v-spacer>
            <div
                v-if="activeTicket"
                class="font-weight-bold text-caption single-line"
            >
                {{ activeTicket.ClientName }} - Ticket #{{ activeTicket.Hash }} <v-icon v-if="activeTicket.IsPrivate" title="Ce ticket est privé">
                    mdi-incognito-circle
                </v-icon>
            </div>
            <v-skeleton-loader
                v-else
                type="text"
                class="mt-2 mr-4"
            />
        </v-toolbar>

        <div
            v-if="!activeTicket"
            class=""
        >
            <v-skeleton-loader
                v-for="i in 5"
                :key="i"
                type="list-item-two-line"
            />
        </div>
        <v-sheet
            v-else
            id="chat-container"
            max-height="50vh"
            class="pa-4 overflow-y-auto"
        >
            <v-sheet
                rounded
                outlined
                class="pa-3 mb-4"
            >
                <div class="text--secondary text-caption">
                    Message initial par <strong>{{ activeTicket.CreatedByName }}</strong>
                    {{ formatDateTimeHuman(activeTicket.CreatedDate) }}
                </div>
                <div v-html="activeTicket.InitialMessage.replace('/api/v1/upload/','/api/v1/odeon/api/v1/upload/')" />
            </v-sheet>

            <v-dialog
                v-model="showImage"
            >
                <v-img :src="'api/v1/odeon/'+showImageSrc" />
            </v-dialog>

            <v-container
                v-for="m in messages"
                :key="m.ID"
                :class="'caption d-flex flex-row ' + messageTypeColor[m.Type] + '--text'"
            >
                <div class="info-timer-block">
                    <v-icon
                        size="16"
                        :color="messageTypeColor[m.Type]"
                    >
                        mdi-{{ messageTypeIcon[m.Type] }}
                    </v-icon>
                    {{ formatDateTimeHuman(m.Timestamp) }}
                    <div
                        v-if="m.SendByID"
                        class="font-weight-bold"
                    >
                        {{ m.SendByName }}
                    </div>
                </div>
                <v-divider
                    class="mx-3"
                    vertical
                />
                <div
                    v-if="[MessageType.System, MessageType.Information, MessageType.Resolution].includes(m.Type)"
                    v-html="m.Message"
                />
                <v-sheet
                    v-else-if="m.Type === MessageType.Note"
                    rounded
                    outlined
                    class="pa-3 text-body-2"
                    :color="$vuetify.theme.dark ? 'deep-orange darken-4' : 'orange'"
                    v-html="m.Message"
                />
                <v-sheet
                    v-else
                    rounded
                    outlined
                    class="pa-3 text-body-2"
                    v-html="m.Message"
                />
            </v-container>
        </v-sheet>

        <v-spacer />
        <div class="d-flex flex-column">
            <v-divider />
            <div class="grey-border-top full-width flex-fill">
                <tiptap-editor
                    ref="editor"
                    v-model="editorMessage"
                    placeholder="Votre réponse ici"
                />
            </div>

            <v-divider />

            <div class="mx-3 my-2">
                <div layout="row" layout-align="start center" class="mb-2">
                    <span class="mr-3 text-subtitle-2">Fichiers à joindre</span>

                    <v-tooltip fixed bottom>
                        <template v-slot:activator="{on}">
                            <v-btn icon small color="primary" v-on="on" @click="addAttachement">
                                <v-icon small>mdi-attachment-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Joindre un fichier</span>
                    </v-tooltip>
                    <input
                        ref="fileUpload"
                        hidden
                        type="file"
                        @change="onFileSelected"
                    >
                </div>
                <div v-if="attachments && attachments.length">
                    <ul style="list-style-type: none;margin: 0;padding: 0;" layout="row" layout-align="start center"
                        v-for="(a, idx) in attachments"  :key="idx" >
                        <li>
                            <v-icon v-if="a.type === 'application/pdf'">mdi-file-pdf-box</v-icon>
                            <v-icon v-else-if="a.type.startsWith('text/')">mdi-text-box-outline</v-icon>
                            <v-icon v-else-if="a.type.startsWith('image/')">mdi-image</v-icon>
                            <v-icon v-else-if="a.type.startsWith('video/')">mdi-video</v-icon>
                            <v-icon v-else>mdi-file-outline</v-icon>
                        </li>
                        <span class="mx-3"> {{ a.name }}</span>
                        <v-btn icon color="red" @click="deleteAttachment(idx)"><v-icon>mdi-close</v-icon></v-btn>
                    </ul>
                </div>
                <div v-else>Aucun fichier joint</div>
            </div>

            <v-divider />

            <div class="flex-row text-right pa-2">
                <v-btn
                    v-if="!customer"
                    depressed
                    outlined
                    color="orange"
                    :disabled="!activeTicket"
                    @click="sendNote"
                >
                    <v-icon left>
                        mdi-note-outline
                    </v-icon>
                    Envoyer en note
                </v-btn>
                <v-btn
                    color="green"
                    text
                    class="ml-1 white--text"
                    :disabled="!activeTicket || !stripTags(editorMessage.trim())"
                    @click="sendChat"
                >
                    <v-icon left>
                        mdi-chat
                    </v-icon>
                    {{ customer ? 'Envoyer la réponse' : 'Envoyer au client' }}
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import DateMixin from '@/mixins/date'
import OdeonApi from '@/api/odeon'
import TiptapEditor from '@/components/odeon/TiptapEditor'
import eventbus from '@/eventbus'

export default {
    mixins: [DateMixin],

    components: { TiptapEditor },

    props: {
        customer: {
            type: Boolean,
            required: false,
            default: false
        },
        activeTicket: {
            type: Object,
            default () { return {} }

        }
    },

    data () {
        return {
            editorMessage: '',
            messages: [],
            messageTypeColor: {
                1: 'indigo', // System
                2: 'blue', // Information
                3: '', // Chat
                4: 'deep-orange', // Note
                5: 'green' // Resolution
            },
            messageTypeIcon: {
                1: 'cog', // System
                2: 'information-outline', // Information
                3: 'chat', // Chat
                4: 'note', // Note
                5: 'check' // Resolution
            },
            MessageType: {
                System: 1,
                Information: 2,
                Chat: 3,
                Note: 4,
                Resolution: 5
            },
            showImageSrc: null,
            showImage: false,
            attachments: []
        }
    },

    watch: {
        activeTicket () {
            this.fetchMessages()
        },

        messages () {
            this.$nextTick(() => {
                const messageImages = document.querySelectorAll('#chat-container img')
                for (const messageImage of messageImages) {
                    messageImage.onclick = () => {
                        this.showImageSrc = messageImage.getAttribute('src')
                        this.showImage = true
                    }
                }
            })
        }
    },

    mounted () {
        this.fetchMessages()

        eventbus.$on('odeon-new-ticket-message', this.handleNewTicketMessage)
    },

    beforeDestroy () {
        eventbus.$off('odeon-new-ticket-message', this.handleNewTicketMessage)
    },

    methods: {
        back () {
            this.$emit('back')
        },

        stripTags (input) {
            return input.replace(/<\/?[^>]+(>|$)/g, '')
        },

        getShortTitle (title, length = 60) {
            if (title.length > length) {
                title = title.substring(0, length) + '...'
            }

            return title
        },

        // parse message convert image src for odeon resa proxy
        parseMessage (messageContent) {
            const parser = new DOMParser()
            const doc = parser.parseFromString(messageContent, 'text/html')

            // change url for img
            const imgs = doc.querySelectorAll('img')
            for (const img of imgs) {
                const imageSrc = `/api/v1/odeon${img.getAttribute('src')}`
                img.setAttribute('src', imageSrc)
            }

            // change url for a
            const links = doc.querySelectorAll('a')
            for (const link of links) {
                const href = `/api/v1/odeon${link.getAttribute('href')}`
                link.setAttribute('href', href)
            }

            return doc.querySelector('body').innerHTML
        },

        formatMessage (messageContent) {
            const parser = new DOMParser()
            const doc = parser.parseFromString(messageContent, 'text/html')
            const imgs = doc.querySelectorAll('img')
            for (const img of imgs) {
                const imageSrc = img.getAttribute('src')
                img.setAttribute('src', imageSrc)
            }
            return doc.querySelector('body').innerHTML
        },

        async sendChat () {
            try {
                if (this.attachments && this.attachments.length) {
                    for (const file of this.attachments) {
                        const uploadRes = await OdeonApi.postFile(file)
                        await OdeonApi.createTicketAttachment({
                            Filename: file.name,
                            AttachmentID: uploadRes.data.Identifier,
                            TicketID: this.activeTicket.ID,
                            MimeType: file.type
                        })
                    }
                    this.attachments = []
                }

                await OdeonApi.createTicketMessage({
                    TicketID: this.activeTicket.ID,
                    Message: this.formatMessage(this.editorMessage.replace('api/v1/odeon/', ''))
                })

                this.fetchMessages(true)
                this.$refs.editor.clear()
                this.editorMessage = ''
            } catch (e) {
                console.error(e)
                this.$snotify.error('Impossile d\'envoyer le message')
            }
        },

        fetchMessages (doScroll) {
            if (this.activeTicket) {
                OdeonApi.getTicketMessages(this.activeTicket.ID).then(res => {
                    this.messages = res.data.map(m => Object.assign(m, { Message: this.parseMessage(m.Message) }))

                    if (doScroll) {
                        this.$nextTick(() => {
                            const objDiv = document.getElementById('chat-container')
                            if (objDiv) {
                                objDiv.scrollTop = objDiv.scrollHeight
                            }
                        })
                    }
                })
            }
        },

        handleNewTicketMessage () {
            this.fetchMessages()
        },

        addAttachement () {
            this.$refs.fileUpload.click()
        },

        deleteAttachment (index) {
            this.attachments.splice(index, 1)
        },

        onFileSelected () {
            this.attachments.push(this.$refs.fileUpload.files[0])
        }
    }
}
</script>

<style lang="scss">
#chat-container img {
  max-width: 100%;
}

</style>
