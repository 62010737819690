// store modules
import resa from './stats/resa/resa.js'

const initialState = () => ({
    setup: {
        navigationDrawer: {
            visible: false,
            miniState: false,
            prefenreceMiniState: false
        }
    }
})

export default {
    modules: {
        resa
    },
    state: initialState,
    actions: {
        /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        reset_stats (state) {
            const initial = initialState()
            Object.keys(initial).forEach(key => { state[key] = initial[key] })
        }
    }
}
