import './common'
import axios from 'axios'

export default {
    login: user => axios.post('/auth/login', user),
    loginToken: token => axios.post('/auth/login-token', { Token: token }),
    logout: () => axios.post('/auth/logout'),
    getAuthToken: data => axios.post('/auth-cas/token', data),
    checkAuthToken: (token) => axios.get(`/auth-cas/token/${token}`)
}
