import Vue from 'vue'
import 'vue-snotify/styles/material.css'
import Snotify, { SnotifyPosition } from 'vue-snotify'

Vue.use(Snotify, {
    toast: {
        position: SnotifyPosition.rightBottom,
        closeOnClick: true,
        timeout: 3000,
        titleMaxLength: 40,
        showProgressBar: false
    }
})
