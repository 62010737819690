import Consts from '../consts'

const deliveryVoucherStatuses = [
    { value: Consts.DELIVERY_VOUCHER_STATUS.DRAFT, text: "En cours d'élaboration", color: "grey", icon: "mdi-pencil" },
    { value: Consts.DELIVERY_VOUCHER_STATUS.READY_TO_SEND, text: "Prêt à l'envoi", color: "yellow darken-1", icon: "mdi-email-arrow-right-outline" },
    { value: Consts.DELIVERY_VOUCHER_STATUS.SENDED, text: "Envoyé", color: "blue", icon: "mdi-email-check-outline" },
    { value: Consts.DELIVERY_VOUCHER_STATUS.PAID, text: "Payé", color: "green", icon: "mdi-cash-check"},
    { value: Consts.DELIVERY_VOUCHER_STATUS.REFUSED, text: "Refusé par le client", color: "orange", icon: "mdi-hand-back-left" },
    { value: Consts.DELIVERY_VOUCHER_STATUS.CANCELLED, text: "Annulé", color: "red", icon: "mdi-cancel"},
]
export default {
    deliveryVoucherStatus(v) {
        return deliveryVoucherStatuses.find(s => s.value == v)
    },
}

export { deliveryVoucherStatuses }
