import SpaApi from '~/api/spa'
import _ from 'lodash'
import moment from 'moment'
import Vue from 'vue'

const initialState = () => ({
    /* remote */
    universes: [],
    universe: { ID: 'default' },
    spaActivities: [],
    spaProducts: [],
    personnels: [],
    resources: [],
    /* local */
    spaParams: {
        spaPlanningsStartHour: 7,
        spaPlanningsStartMin: 0,
        spaPlanningsEndHour: 20,
        spaPlanningsEndMin: 0,
        spaHumanConfirmation: false,
        spaShowShortLabel: false,
        spaColumnsShortLabel: false,
        spaGoBackToday: false,
        firstInterval: 7,
        intervalCount: 14,
        allowedHours: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
        dayDuration: 14,
        unavailabilitiesReasons: [],
        unavailabilitiesColors: []
    },
    planningDate: { default: moment().format('YYYY-MM-DD') },
    planningPersonnels: { default: [] },
    planningResources: { default: [] },
    showPersonnels: { default: true },
    showResources: { default: true },
    intervalMinutes: { default: 10 },
    intervalHeight: { default: 25 }
})

export default {
    state: initialState,
    getters: {
        universes: state => state.universes,
        universe: state => state.universe,
        spaActivities: state => state.spaActivities[state.universe.ID] ?? [],
        spaActivitiesById: state => _.keyBy(state.spaActivities[state.universe.ID] ?? [], 'ID'),
        spaProducts: state => state.spaProducts[state.universe.ID] ?? [],
        spaProductsById: state => _.keyBy(state.spaProducts[state.universe.ID] ?? [], 'Product.ID'),
        personnels: state => state.personnels[state.universe.ID] ?? [],
        personnelsById: state => _.keyBy(state.personnels[state.universe.ID] ?? [], 'ID'),
        resources: state => state.resources[state.universe.ID] ?? [],
        spaParams: state => state.spaParams,
        resourcesById: state => _.keyBy(state.resources[state.universe.ID] ?? [], 'ID'),
        planningDate: state => state.planningDate[state.universe.ID] ?? moment().format('YYYY-MM-DD'),
        planningPersonnels: state => state.planningPersonnels[state.universe.ID] ?? [],
        planningResources: state => state.planningResources[state.universe.ID] ?? [],
        showPersonnels: state => state.showPersonnels[state.universe.ID] ?? true,
        showResources: state => state.showResources[state.universe.ID] ?? true,
        intervalMinutes: state => state.intervalMinutes[state.universe.ID] ?? 10,
        intervalHeight: state => state.intervalHeight[state.universe.ID] ?? 25
    },
    actions: {
        getUniverses ({ commit, state }) {
            return new Promise((resolve, reject) => {
                SpaApi.getSpaUniverses().then(res => {
                    const universes = res.data ?? []
                    commit('set_universes', universes)
                    if (state.universe === {} || universes.findIndex(u => u.ID === state.universe.ID) === -1) {
                        commit('set_universe', universes.find(u => u.State) ?? {})
                    } else {
                        const uIdx = universes.findIndex(u => u.ID === state.universe.ID)
                        commit('set_universe', universes[uIdx])
                    }
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getSpaActivities ({ commit }) {
            return new Promise((resolve, reject) => {
                SpaApi.getSpaActivities().then(res => {
                    commit('set_spa_activities', _.groupBy(res.data ?? [], 'UniverseID'))
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getSpaProducts ({ commit }) {
            return new Promise((resolve, reject) => {
                SpaApi.getSpaProducts({ State: true }).then(res => {
                    commit('set_spa_products', _.groupBy(res.data ?? [], 'Link.UniverseID'))
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getPersonnels ({ commit, state }) {
            return new Promise((resolve, reject) => {
                SpaApi.getSpaPersonnels({ State: true }).then(res => {
                    commit('set_personnels', _.groupBy(res.data ?? [], 'UniverseID'))

                    // Ensure that there is no disabled personnel still in planning
                    if (res.data && state.planningPersonnels[state.universe.ID]) {
                        commit('set_planning_personnels', state.planningPersonnels[state.universe.ID].filter(id => res.data.some(r => r.ID === id)))
                    }
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getResources ({ commit }) {
            return new Promise((resolve, reject) => {
                SpaApi.getSpaResources({ State: true }).then(res => {
                    commit('set_resources', _.groupBy(res.data ?? [], 'UniverseID'))
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        }
    },
    mutations: {
        reset_spa (state) {
            const initial = initialState()
            Object.keys(initial).forEach(key => { state[key] = initial[key] })
        },
        set_universes (state, val) {
            state.universes = val
        },
        set_universe (state, val) {
            state.universe = val

            const startTime = moment(state.universe.Params.plannings_start_time, 'hh:mm')
            const endTime = moment(state.universe.Params.plannings_end_time, 'hh:mm')
            const intervalCount = endTime.hour() - startTime.hour() + (endTime.minute() !== 0 ? 1 : 0)

            state.spaParams = {
                spaPlanningsStartHour: startTime.hour(),
                spaPlanningsStartMin: startTime.minute(),
                spaPlanningsEndHour: endTime.hour(),
                spaPlanningsEndMin: endTime.minute(),
                spaHumanConfirmation: state.universe.Params.human_confirmation,
                spaShowShortLabel: state.universe.Params.show_short_label,
                spaColumnsShortLabel: state.universe.Params.columns_short_label,
                spaGoBackToday: state.universe.Params.go_back_today,
                spaSmallCalendarIntervalWidth: state.universe.Params.small_calendar_interval_width,
                spaWebAllowedTimetables: state.universe.Params.web_allowed_timetables,
                unavailabilitiesReasons: state.universe.Params.unavailabilities_reasons,
                unavailabilitiesColors: state.universe.Params.unavailabilities_colors,
                firstInterval: startTime.hour(),
                intervalCount,
                allowedHours: Array.from(Array(intervalCount), (x, index) => startTime.hour() + index),
                dayDuration: endTime.diff(startTime, 'minutes')
            }
        },
        set_spa_activities (state, val) {
            state.spaActivities = val
        },
        set_spa_products (state, val) {
            state.spaProducts = val
        },
        set_personnels (state, val) {
            state.personnels = val
        },
        set_resources (state, val) {
            state.resources = val
        },
        set_planning_date (state, val) {
            Vue.set(state.planningDate, state.universe.ID, val)
        },
        set_planning_personnels (state, val) {
            Vue.set(state.planningPersonnels, state.universe.ID, val)
        },
        set_planning_resources (state, val) {
            Vue.set(state.planningResources, state.universe.ID, val)
        },
        set_show_personnels (state, val) {
            Vue.set(state.showPersonnels, state.universe.ID, val)
        },
        set_show_resources (state, val) {
            Vue.set(state.showResources, state.universe.ID, val)
        },
        set_interval_minutes (state, val) {
            Vue.set(state.intervalMinutes, state.universe.ID, val)
        },
        set_interval_height (state, val) {
            Vue.set(state.intervalHeight, state.universe.ID, val)
        }
    }
}
