import eventbus from '../eventbus'

export default {
    data: () => ({
        webviewData: global.cashRegisterIPC ? global.cashRegisterIPC.data : null
    }),

    mounted () {
        if (!global.eventbus) {
            global.eventbus = eventbus
        }
    },
    methods: {
        // need to be a method, if computed, it is not triggered because global.cashRegisterIPC is not part of vuejs reactivity
        wvEnabled () {
            return !!global.cashRegisterIPC
        },

        wvGetData () {
            return this.wvEnabled() ? global.cashRegisterIPC.data : null
        },

        wvGetDataValue (key) {
            return this.wvEnabled() && global.cashRegisterIPC.data ? global.cashRegisterIPC.data[key] : null
        },

        wvEmit (channel, args) {
            if (this.wvEnabled()) {
                global.cashRegisterIPC.emit(channel, args)
            } else {
                console.error(`can't emit ${channel} from webview mixin: no cashregister ipc`)
            }
        },

        wvEmitPromise (channel, args) {
            if (this.wvEnabled()) {
                return global.cashRegisterIPC.emitPromise(channel, args)
            } else {
                console.error(`can't emit ${channel} from webview mixin: no cashregister ipc`)
            }
        },

        wvReady () {
            this.wvEmit('ready')
        },

        wvClose () {
            this.wvEmit('close')
        },

        wvAuthError (message) {
            this.wvEmit('auth-error', message)
        },

        wvLogout () {
            this.wvEmit('logout')
        },

        wvLoggedIn () {
            this.wvEmit('logged-in')
        },

        wvResumeSale (saleId) {
            this.wvEmit('resume-sale', { SaleID: saleId })
        },

        wvRemoveSaleLine (saleLineID, clientID) {
            this.wvEmit('remove-saleline', { saleLineID, clientID })
        },

        wvVoucherValidated (voucher) {
            this.wvEmit('voucher-validated', voucher)
        },

        wvAssociateCard (cardType, clientID) {
            return this.wvEmitPromise('associate-card', { cardType, clientID })
        },

        wvToast (type, message) {
            this.wvEmit('toast-message', { type, message })
        },

        wvOdeonUnreadAmount (unreadAmount) {
            this.wvEmit('odeon-unread-amount', unreadAmount)
        },

        wvPeriodicBookingSaved (periodicBookingID) {
            this.wvEmit('periodic-booking-saved', periodicBookingID)
        }
    }
}
