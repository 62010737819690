import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters(['establishments', 'areas', 'products', 'modules'])
    },

    methods: {
        getEstablishmentFromID (establishmentID) {
            const establishment = this.establishments.find(a => a.ID === establishmentID)
            if (!establishment) {
                return { Label: '-' }
            }
            return establishment
        },

        getEstablishmentAreas (establishmentID) {
            const areas = this.areas.filter(e => e.EstablishmentID === establishmentID)
            if (!areas) {
                return { Label: '-' }
            }
            return areas
        },

        getAreaFromID (areaID) {
            const area = this.areas.find(a => a.ID === areaID)
            if (!area) {
                return { Label: '-' }
            }
            return area
        },

        getProductByID (productID) {
            const product = this.products.find(p => p.ID === productID)
            if (!product) {
                return { Label: { FR: '-' } }
            }
            return product
        },

        async getDefaultRoute () {
            const defaultRoutes = {
                planning: 'planning-index',
                ctm: 'turnstiles-status',
                clients: 'clients-index',
                spa: 'spa-index',
                cashregister: 'cashregister-index',
                stats: 'stats-dashboard',
                lockers: 'lockers-manager',
                activities: 'activities-index',
                kiosks: 'kiosks-index',
                quotations: 'quotation-index',
                displayland: 'displayland-index'
            }
            let nextRoute

            if (this.$store.getters.authUser && this.$store.getters.authUser.DefaultModule) {
                nextRoute = defaultRoutes[this.$store.getters.authUser.DefaultModule]
            } else {
                const res = await this.$store.dispatch('getParams')
                if (res.data !== null && defaultRoutes[this.formatParams(res.data, 'main').mainDefaultModule]) {
                    nextRoute = defaultRoutes[this.formatParams(res.data, 'main').mainDefaultModule]
                }
            }

            if (!nextRoute) {
                nextRoute = 'planning-index'
            }

            return nextRoute
        }
    }
}
