export default [
    {
        path: '/clients',
        name: 'clients-index',
        meta: {
            title: 'Clients'
        },
        redirect: { name: 'list' },
        component: () => import('~/views/clients/Index.vue'),
        children: [
            {
                path: 'list',
                name: 'list',
                meta: {
                    title: 'Liste des clients'
                },
                component: () => import('~/views/clients/ClientsList.vue')
            },
            {
                path: '/clients/clubs',
                name: 'clubs',
                meta: {
                    title: 'Liste des clubs'
                },
                component: () => import('~/views/clients/Clubs.vue')
            },
            {
                path: 'planning-clubs',
                name: 'planning-clubs',
                meta: {
                    title: 'Planning des clubs'
                },
                component: () => import('~/views/clients/ClubsPlanning.vue')
            },

            {
                path: '/clients/clubs/:id',
                name: 'clubs-show',
                meta: {
                    title: 'Clubs'
                },
                component: () => import('~/views/clients/Clubs.vue')
            }
        ]
    },
    {
        path: '/clients/:id',
        name: 'clients-show',
        meta: {
            title: 'Clients'
        },
        component: () => import('~/views/clients/ClientsShow.vue')
    },
    {
        path: '/clients/clubs/new',
        name: 'clubs-new',
        meta: {
            title: 'Création d\'un club'
        },
        component: () => import('~/components/clients/CreateClub.vue')
    }
]
