export default [
  {
    path: '/displayland',
    name: 'displayland-index',
    meta: {
      title: 'Gestion des affichages'
    },
    redirect: { name: 'displayland-dashboard' },
    component: () => import('~/views/displayland/Index.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'displayland-dashboard',
        meta: {
          title: 'Tableau de bord'
        },
        component: () => import('~/views/displayland/Dashboard.vue')
      },
      {
        path: 'configurations',
        name: 'displayland-configurations',
        meta: {
          title: 'Configuration des écrans'
        },
        component: () => import('~/views/displayland/Configurations.vue')
      },
      {
        path: 'media',
        name: 'displayland-media',
        meta: {
          title: 'Medias'
        },
        component: () => import('~/views/displayland/Medias.vue')
      },
      {
        path: '/display/:id',
        name: 'displayland-view',
        meta: {
          title: 'Ecran',
          disableDashboardLayout: true
        },
        component: () => import('~/views/displayland/View.vue')
      }
    ]
  }
]
