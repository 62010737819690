import Consts from '../consts'

const lockersStates = [
    { text: 'Ouvert', value: Consts.LOCKERS.LOCKER_STATE.STATE_OPENED, color: '#71DBBA' },
    { text: 'Fermé', value: Consts.LOCKERS.LOCKER_STATE.STATE_CLOSED, color: '#FA906A' },
    { text: 'Erreur', value: Consts.LOCKERS.LOCKER_STATE.STATE_ERROR, color: '#E35E7E' },
    { text: 'Déconnecté', value: Consts.LOCKERS.LOCKER_STATE.STATE_UNKNOWN, color: '#000000' }
]

const lockersStatuses = [
    { text: 'Disponible', value: Consts.LOCKERS.LOCKER_STATUS.STATUS_AVAILABLE },
    { text: 'Occupé', value: Consts.LOCKERS.LOCKER_STATUS.STATUS_IN_USE },
    { text: 'Préparé', value: Consts.LOCKERS.LOCKER_STATUS.STATUS_PREPARED },
    { text: 'En retard', value: Consts.LOCKERS.LOCKER_STATUS.STATUS_OVERSTAY },
    { text: 'Désactivé', value: Consts.LOCKERS.LOCKER_STATUS.STATUS_DISABLED },
    { text: 'En erreur', value: Consts.LOCKERS.LOCKER_STATUS.STATUS_IN_ERROR }
]

const lockersAccessModes = [
    { text: 'Libre', value: Consts.LOCKERS.LOCKER_ACCESS_MODE.FREE_ACCESS },
    { text: 'Réservation', value: Consts.LOCKERS.LOCKER_ACCESS_MODE.RESERVATION },
    { text: 'Affectation', value: Consts.LOCKERS.LOCKER_ACCESS_MODE.AFFECTATION }
]

const lockersBookingModes = [
    { 
        text: 'Réservation ponctuelle', 
        value: Consts.LOCKERS.LOCKER_BOOKING_MODE.MODE_BOOKING, 
        icon: 'mdi-clock-outline', 
        detail: 'Réservation pour une date donnée, avec une heure de début et de fin', 
    },
    { 
        text: 'Affectation', 
        value: Consts.LOCKERS.LOCKER_BOOKING_MODE.MODE_AFFECTATION, 
        icon: 'mdi-lock-outline', 
        detail: 'Réservation pour une date indéfinie',
     },
    { 
        text: 'Usage unique', 
        value: Consts.LOCKERS.LOCKER_BOOKING_MODE.MODE_SINGLE_USE, 
        icon: 'mdi-package-variant-closed', 
        detail: "Réservation du casier jusqu'à sa prochaine ouverture", 
    }
]

const lockersIncidentsTypes = [
    { text: 'Autre incident', value: Consts.LOCKERS.INCIDENT_TYPE.OTHER_INCIDENT },
    { text: 'Echec d\'attribution d\'un casier', value: Consts.LOCKERS.INCIDENT_TYPE.NO_LOCKERS_AVAILABLE_AT_BOOKING_START_INCIDENT },
    { text: 'Echec de réattribution d\'un casier', value: Consts.LOCKERS.INCIDENT_TYPE.NO_LOCKERS_AVAILABLE_AT_BOOKING_RETRY_INCIDENT },
    { text: 'Casier fermé après la fin de la réservation', value: Consts.LOCKERS.INCIDENT_TYPE.NOT_OPENED_AT_THE_END_OF_BOOKING_INCIDENT },
    { text: 'Batterie de casier faible', value: Consts.LOCKERS.INCIDENT_TYPE.LOW_BATTERY_LOCKER_INCIDENT },
    { text: 'L\'ouverture du casier a échoué', value: Consts.LOCKERS.INCIDENT_TYPE.LOCKER_STATE_NOT_OPENED_INCIDENT },
    { text: 'La fermeture du casier a échoué', value: Consts.LOCKERS.INCIDENT_TYPE.LOCKER_STATE_NOT_CLOSED_INCIDENT },
    { text: 'Passerelle déconnectée', value: Consts.LOCKERS.INCIDENT_TYPE.OFFLINE_GATEWAY_INCIDENT },
    { text: 'Casier déconnecté', value: Consts.LOCKERS.INCIDENT_TYPE.OFFLINE_LOCKER_INCIDENT },
    { text: 'Code maitre invalide', value: Consts.LOCKERS.INCIDENT_TYPE.NON_VALID_MASTER_CODE_LOCKER_INCIDENT }
]

const lockersBookingStates = [
    { text: 'A venir', value: Consts.LOCKERS.BOOKING_STATE.BOOKING_REGISTERED, color:"blue" },
    { text: 'En cours', value: Consts.LOCKERS.BOOKING_STATE.BOOKING_STARTED, color:"green" },
    { text: 'Terminée', value: Consts.LOCKERS.BOOKING_STATE.BOOKING_ENDED, color:"black" },
    { text: 'Annulée', value: Consts.LOCKERS.BOOKING_STATE.BOOKING_CANCELED, color:"blue-grey lighten-3" },
    { text: 'En retard', value: Consts.LOCKERS.BOOKING_STATE.BOOKING_OVERSTAY, color:"orange" },
    { text: 'Erreur', value: Consts.LOCKERS.BOOKING_STATE.BOOKING_ERROR, color:"error accent-4" },
    { text: 'Terminée en retard', value: Consts.LOCKERS.BOOKING_STATE.BOOKING_ENDED_WITH_OVERSTAY, color:"error purple" }
]

const lockersEventTypes = [
    { text: 'DST time advance false', value: Consts.LOCKERS.EVENT_TYPE.TIME_ADVANCE },
	{ text: 'DST time delay false', value: Consts.LOCKERS.EVENT_TYPE.TIME_DELAY },
	{ text: 'Erreur mécanique lors de l\'ouverture/fermeture', value: Consts.LOCKERS.EVENT_TYPE.MECHANICAL_FAILURE },
	{ text: 'Ouverture avec code utilisateur', value: Consts.LOCKERS.EVENT_TYPE.USER_OPENING },
	{ text: 'Ouverture applicative avec code maitre', value: Consts.LOCKERS.EVENT_TYPE.MASTER_OPENING},
	{ text: 'Fermeture avec code utilisateur', value: Consts.LOCKERS.EVENT_TYPE.USER_CLOSING },
	{ text: 'Configuration de la serrure terminée', value: Consts.LOCKERS.EVENT_TYPE.INITIALIZATION },
	{ text: 'Ouverture automatique à la fin de la location', value: Consts.LOCKERS.EVENT_TYPE.AUTOMATIC_OPEN_RENT_TIME },
	{ text: 'Code maitre saisi invalide', value: Consts.LOCKERS.EVENT_TYPE.NON_VALID_MASTER_CODE },
	{ text: 'Le code utilisateur a expiré', value: Consts.LOCKERS.EVENT_TYPE.TIMED_OUT_CODE },
	{ text: 'La serrure a un autre code utilisateur', value: Consts.LOCKERS.EVENT_TYPE.OCCUPIED_CARD },
	{ text: 'Aucun permis disponible', value: Consts.LOCKERS.EVENT_TYPE.NON_VALID_CARD },
	{ text: 'Ouverture au digicode', value: Consts.LOCKERS.EVENT_TYPE.USER_OPENING_KEYPAD },
	{ text: 'Fermeture au digicode', value: Consts.LOCKERS.EVENT_TYPE.USER_CLOSING_KEYPAD },
	{ text: 'Ouverture au digicode avec code maitre', value: Consts.LOCKERS.EVENT_TYPE.MASTER_OPENING_KEYPAD },
	{ text: 'Fermeture au digicode avec code maitre', value: Consts.LOCKERS.EVENT_TYPE.MASTER_CLOSING_KEYPAD },
	{ text: 'Ouverture automatiqe', value: Consts.LOCKERS.EVENT_TYPE.AUTOMATIC_OPENING },
	{ text: 'Fermeture applicative', value: Consts.LOCKERS.EVENT_TYPE.MASTER_CLOSING },
	{ text: 'Batterie faible', value: Consts.LOCKERS.EVENT_TYPE.LOW_BATTERY },
	{ text: 'User cannot close more locks at the same time', value: Consts.LOCKERS.EVENT_TYPE.FULL_CARD },
	{ text: 'Restauration de la configuration d\'origine', value: Consts.LOCKERS.EVENT_TYPE.ERASE_BT },
	{ text: 'Configuration de la serrure en mode libre demandée à partir de Streamlor', value: Consts.LOCKERS.EVENT_TYPE.STREAMLOR_FREE_CONFIGURATION },
	{ text: 'Configuration de la serrure en mode réservable demandée à partir de Streamlor', value: Consts.LOCKERS.EVENT_TYPE.STREAMLOR_DEDICATED_CONFIGURATION },
	{ text: 'Ouverture demandée à partir de Streamlor', value: Consts.LOCKERS.EVENT_TYPE.STREAMLOR_MASTER_OPENING },
	{ text: 'Fermeture demandée à partir de Streamlor', value: Consts.LOCKERS.EVENT_TYPE.STREAMLOR_MASTER_CLOSING },
]

const ledOptionsTypes = [
    { text: 'LED clignottante toutes les 2 secondes', value: 0 },
    { text: 'LED clignottante toutes les 900 millisecondes', value: 1 },
    { text: 'LED allumée', value: 2 },
    { text: 'LED éteinte', value: 3 }
]

const daysTypes = [
    { text: 'Lundi', value: 1 },
    { text: 'Mardi', value: 2 },
    { text: 'Mercredi', value: 3 },
    { text: 'Jeudi', value: 4 },
    { text: 'Vendredi', value: 5 },
    { text: 'Samedi', value: 6 },
    { text: 'Dimanche', value: 7 }
]

export { 
    lockersStates, 
    lockersStatuses, 
    lockersAccessModes, 
    lockersBookingModes,
    lockersIncidentsTypes,
    lockersBookingStates,
    lockersEventTypes,
    ledOptionsTypes,
    daysTypes 
}