const initialState = () => ({
  subscribers: {
    'r://turnstile-info': 0,
    'r://turnstile-history': 0,
    'r://access-control-vouchers': 0,
    'r://clients': 0,
    'r://spa-bookings': 0,
    'r://area-attendance': 0,
    'r://establishments': 0,
    'r://areas': 0,
    'r://lockers': 0,
    'r://locker-action/state': 0,
    'r://locker-action/config': 0,
    'r://locker-action/history': 0,
    'r://locker-blocks': 0,
    'r://locker-gateways': 0,
    'r://locker-bookings': 0,
    'r://locker-bookings-new': 0,
    'r://locker-incidents-new': 0,
    'r://locker-incidents-update': 0,
    'r://vouchersheets': 0,
    'r://vouchersheet-progress': 0,
    'r://kiosks': 0,
    'r://displays': 0,
    'r://products': 0
  }
})

export default {
  state: initialState,
  getters: {
    getSubscribersFor: state => r => {
      return isNaN(state.subscribers[r]) ? 0 : state.subscribers[r]
    }
  },
  actions: {
    resetSubscribers ({ commit }) {
      commit('reset_subscribers')
    },
    subscribeTo ({ commit }, r) {
      commit('subscribe', r)
    },
    unsubscribeFrom ({ commit }, r) {
      commit('unsubscribe', r)
    }
  },
  mutations: {
    reset_ws (state) {
      const initial = initialState()
      Object.keys(initial).forEach(key => {
        state[key] = initial[key]
      })
    },
    reset_subscribers (state) {
      state.subscribers = {
        'r://turnstile-info': 0,
        'r://turnstile-history': 0,
        'r://access-control-vouchers': 0,
        'r://clients': 0,
        'r://spa-bookings': 0,
        'r://area-attendance': 0,
        'r://establishments': 0,
        'r://areas': 0,
        'r://lockers': 0,
        'r://locker-action/state': 0,
        'r://locker-action/config': 0,
        'r://locker-action/history': 0,
        'r://locker-blocks': 0,
        'r://locker-gateways': 0,
        'r://locker-bookings': 0,
        'r://locker-bookings-new': 0,
        'r://locker-incidents-new': 0,
        'r://locker-incidents-update': 0,
        'r://vouchersheets': 0,
        'r://vouchersheet-progress': 0,
        'r://kiosks': 0,
        'r://displays': 0,
        'r://products': 0
      }
    },
    subscribe (state, r) {
      state.subscribers[r]++
    },
    unsubscribe (state, r) {
      if (state.subscribers[r] > 0) {
        state.subscribers[r]--
      }
    }
  }
}
