import {
    lockersStates, 
    lockersStatuses, 
    lockersAccessModes, 
    lockersBookingModes,
    lockersBookingStates,
    lockersIncidentsTypes, 
    lockersEventTypes, 
    ledOptionsTypes, 
    daysTypes
} from '../ui/lockers'

export default {
    lockersState(x) {
        return lockersStates.find(t => t.value == x)
    },
    lockersStatus(x) {
        return lockersStatuses.find(t => t.value == x)
    },
    lockersAccessMode(x) {
        return lockersAccessModes.find(t => t.value == x)
    },
    lockersBookingMode(x) {
        return lockersBookingModes.find(t => t.value == x)
    },
    lockersIncidentType(x) {
        return lockersIncidentsTypes.find(t => t.value == x)
    },
    lockersBookingState(x) {
        return lockersBookingStates.find(t => t.value == x)
    },
    lockersEventType(x) {
        return lockersEventTypes.find(t => t.value == x)
    },
    ledOptionsType(x) {
        return ledOptionsTypes.find(t => t.value == x)
    },
    daysType(x) {
        return daysTypes.find(t => t.value == x)
    }
}
