import ApiParams from '~/api/params'
import moment from 'moment'
import _ from 'lodash'
import eventbus from '~/eventbus'

import paramsMixin from 'neptune/helpers/params'

const initialState = () => ({
    modules: {
        planning: false,
        ctm: false,
        clients: false,
        spa: false,
        stats: false,
        indoorpos: false
    },
    params: null,
    formattedParams: paramsMixin.defaultParams(),
    globalParams: null,

    periods: [],
    pricing: [],
    paymentType: [],
    establishments: [],
    selectedEstablishments: []

})

export default {
    state: initialState,
    getters: {
        modules: state => state.modules,
        params: state => state.params,
        formattedParams: state => state.formattedParams,
        globalParams: state => state.globalParams,
        periods: state => state.periods,
        currentPeriod: function (state) {
            if (state.periods) {
                let current = state.periods.find(p => moment().isBetween(moment(p.StartDate), moment(p.EndDate)))
                if (current === undefined) {
                    current = state.periods[state.periods.length - 1]
                }
                return current
            }
            return {}
        },
        pricing: state => state.pricing,
        paymentType: state => state.paymentType,
        establishments: state => state.establishments,
        establishmentsById: state => _.keyBy(state.establishments, 'ID'),
        selectedEstablishments: state => state.selectedEstablishments,
        statsSeason: state => {
            const season = (state.formattedParams && state.formattedParams.statsSeason) ? state.formattedParams.statsSeason : { start: '01/01', end: '31/12' }
            const start = moment(season.start, 'DD/MM')
            const end = moment(season.end, 'DD/MM')
            return {
                start: {
                    day: start.format('D'),
                    month: start.format('M')
                },
                end: {
                    day: end.format('D'),
                    month: end.format('M')
                }
            }
        },
        currencySymbol: state => {
            if (!state.globalParams[2]) {
                return '?'
            } else if (!state.globalParams[2].Value) {
                return state.globalParams[2].DefaultValue
            }
            return state.globalParams[2].Value
        }
    },
    actions: {
        getModules ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiParams.getModules().then(res => {
                    commit('set_modules', res.data || {})
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getParams ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiParams.getParams().then(res => {
                    commit('set_params', res.data || [])
                    commit('set_formatted_params', paramsMixin.formatParams(res.data || []))
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getGlobalParams ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiParams.getGlobalParams().then(res => {
                    const params = paramsMixin.formatGlobalParams(res.data)
                    commit('set_global_params', params || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getPeriods ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiParams.getPeriods().then(res => {
                    let periods = []
                    if (res.data) {
                        periods = res.data.filter(p => p.Label && p.Label.FR)
                    }
                    commit('set_periods', periods)
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getPricing ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiParams.getPricing().then(res => {
                    commit('set_pricing', res.data || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getPaymentType ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiParams.getPaymentType().then(res => {
                    commit('set_paymentType', res.data || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getEstablishments ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiParams.getEstablishments().then(res => {
                    commit('set_establishments', res.data || [])
                    // By default select no one etab (so no etab condition in filter : so all data are display for all etabs)
                    //                    commit('set_selectedEstablishments', [])
                    // Depend of the etabs (comment or not those lines)
                    // if (this.state.params.selectedEstablishments.length === 0) { // Select At Least One Establishment
                    //     commit('add_selectedEstablishments', res.data[0].Label || []) // by default the first one
                    // }
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getSelectAllEstablishments ({ commit }) {
            commit('set_selectedEstablishments', []) // on vide avant au cas où
            const establishments = JSON.parse(JSON.stringify(this.state.params.establishments))
            establishments.forEach(establishment => {
                commit('add_selectedEstablishments', establishment.ID)
            })
        }
    },

    mutations: {
        reset_params (state) {
            const initial = initialState()
            Object.keys(initial).forEach(key => { state[key] = initial[key] })
        },

        set_modules (state, val) {
            state.modules = val
        },

        set_params (state, val) {
            state.params = val
        },

        set_formatted_params (state, val) {
            state.formattedParams = val
        },

        set_global_params (state, val) {
            state.globalParams = val
        },

        set_periods (state, val) {
            state.periods = val.sort((a, b) => moment(a.EndDate).diff(moment(b.EndDate)))
        },

        set_pricing (state, val) {
            state.pricing = val
        },

        set_paymentType (state, val) {
            state.paymentType = val
        },

        set_establishments (state, val) {
            state.establishments = val
        },

        upsert_establishment (state, val) {
            const index = state.establishments.findIndex(e => e.ID === val.ID)
            if (index === -1) {
                state.establishments.push(val)
            } else {
                state.establishments[index] = val
            }
        },

        set_selectedEstablishments (state, val) {
            const enabledEstablishments = state.establishments.filter(e => e.Status)
            if (enabledEstablishments.length < 2) { // Select nothing if there are only one enabled establishment
                state.selectedEstablishments = []
            } else {
                state.selectedEstablishments = val
            }
            eventbus.$emit('establishments-selected', true)
        },

        add_selectedEstablishments (state, val) {
            state.selectedEstablishments.push(val)
        }
    }
}
