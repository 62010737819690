import './common'
import axios from 'axios'

export default {
    getTickets: (params) => axios.get('/odeon/api/v1/tickets', { params }),
    createTicket: (params) => axios.post('/odeon/api/v1/tickets', params),
    getTicket: (id, params) => axios.get(`/odeon/api/v1/tickets/${id}`, params),
    getTicketMessages: (id, params) => axios.get(`/odeon/api/v1/tickets/${id}/messages`, params),
    createTicketMessage: (data) => axios.post(`/odeon/api/v1/tickets/${data.TicketID}/messages`, data),
    createTicketAttachment: (data) => axios.post(`/odeon/api/v1/tickets/${data.TicketID}/attachments`, data),
    getOdeonParams: (id, params) => axios.get('/odeon-params', params),
    getBookstackToken: (params) => axios.get('/bookstack-token', { params }),
    getOdeonAuthToken: (data) => axios.post('/odeon/api/v1/auth-cas/token', data),
    postFile (file) {
        const formData = new FormData()
        formData.append('file', file)
        return axios.post('/odeon/api/v1/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    }
}
