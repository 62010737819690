import bookings from '../ui/bookings'
import accessControl from '../ui/access-control'

export default {
    /**
    * @deprecated Please use ui/bookings/bookingSource.icon instead
    */
    bookingSourceIcon(x) {
       return bookings.bookingSource(x).icon
    },

    /**
    * @deprecated Please use ui/bookings/bookingStatus.icon instead
    */
    bookingStatusIcon(x) {
        return bookings.bookingStatus(x).icon
    },

    /**
    * @deprecated Please use ui/access-control/turnstilesState.icon instead
    */
    turnstilesStateIcon(state, parentState) {
       return accessControl.turnstilesState(state, parentState).icon
    },

    /**
    * @deprecated Please use ui/access-control/turnstilesTypeIcons instead
    */
    turnstileTypeIcons(t) {
        return accessControl.turnstileType(t).icons
    },
}
