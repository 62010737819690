import ApiDisplayland from '@/api/displayland.js'
import ActivitiesApi from '~/api/activities'

const initialState = () => ({
    displays: [],
    videos: [],
    pdfs: [],
    images: []
})

export default {
    state: initialState,

    getters: {
        displays: state => state.displays,
        videos: state => state.videos,
        pdfs: state => state.pdfs,
        images: state => state.images,
        activities: state => state.activities
    },

    mutations: {
        set_displays (state, val) {
            state.displays = val
        },
        set_videos (state, val) {
            state.videos = val
        },
        set_pdfs (state, val) {
            state.pdfs = val
        },
        set_images (state, val) {
            state.images = val
        },
        set_activities (state, val) {
            state.activities = val
        }

    },
    actions: {
        getDisplays ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiDisplayland.getDisplays().then(res => {
                    commit('set_displays', res.data || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getImages ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiDisplayland.getImages().then(res => {
                    commit('set_images', res.data || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getVideos ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiDisplayland.getVideos().then(res => {
                    commit('set_videos', res.data || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getPdfs ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiDisplayland.getPdfs().then(res => {
                    commit('set_pdfs', res.data || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getActivities ({ commit }) {
            return new Promise((resolve, reject) => {
                ActivitiesApi.getActivities({ State: true }).then(res => {
                    commit('set_activities', res.data)
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        }
    }
}
