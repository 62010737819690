import date from '../helpers/date'
import Consts from '../consts'

const subscriptionTypes = [
  { label: 'Crédit', value: Consts.SUBSCRIPTION_TYPE_CREDIT },
  { label: 'Abonnement', value: Consts.SUBSCRIPTION_TYPE_SUBSCRIPTION },
  { label: 'Quantité', value: Consts.SUBSCRIPTION_TYPE_PASS_Q },
  { label: 'Horaire', value: Consts.SUBSCRIPTION_TYPE_PASS_H },
  { label: 'Pack', value: Consts.SUBSCRIPTION_TYPE_PACK },
  { label: 'Autre', value: Consts.SUBSCRIPTION_TYPE_OTHER },
  { label: 'QR code', value: Consts.SUBSCRIPTION_TYPE_2DTAG }
]

const validityTypes = [
  {
      value: Consts.PRODUCT_VALIDITY_SALE_DATE,
      label: "À l'achat",
      description: "La validité du produit débutera dès l'achat du produit"
  },
  {
      value: Consts.PRODUCT_VALIDITY_FIRST_USE,
      label: 'À la première utilisation',
      description: 'Le validité du produit débutera au premier passage'
  },
  {
      value: Consts.PRODUCT_VALIDITY_FIXED,
      label: 'Date fixe',
      description: 'Le validité du produit débutera à la date fixée dans la fiche produit'
  },
  {
      value: Consts.PRODUCT_VALIDITY_CUSTOM_DATE,
      label:  "Date fixée à l'achat",
      description: "Le produit sera valable à compter de la date fixée lors de l'ajout du produit au panier"
  },
  {
    value: Consts.PRODUCT_VALIDITY_MATCH_BOOKING_DATE,
    label:  "Date calée sur la réservation",
    description: "Le produit sera valable à compter du début de la réservation et jusqu'à sa fin",
  },
  {
    value: Consts.PRODUCT_VALIDITY_CUSTOM_PERIOD,
    label:  "Période de validité fixée à l'achat",
    description: "Le produit sera valable dans l'intervalle des dates fixées lors de l'ajout du produit au panier",
  } 
]

const durationTypes = [
  {value: Consts.DURATION_PER_DAY, label: 'jour(s)'},
  {value: Consts.DURATION_PER_MONTH, label: 'mois'},
  {value: Consts.DURATION_PER_YEAR, label: 'année(s)'}
]

const frequencies = [
  { label: 'Ne pas renouveler', value: Consts.FREQUENCY_NEVER},
  { label: 'Mensuel', value: Consts.FREQUENCY_MONTHLY },
  { label: 'Annuel', value: Consts.FREQUENCY_YEARLY }
]

const frequenciesAlt = [
  {text: "Mois", value: 2},
  {text: "An", value: 3}
]

const firstPurchaseModes = [
  {label: "Ne pas renouveler", value: null},
  {label: 'Date fixe', value: Consts.MODE_FIRST_PURCHASE_FIX_DATES},
  {label: 'Date à date', value: Consts.MODE_FIRST_PURCHASE_DATE_TO_DATE}
]

export default {
  durationType(x) {
    return durationTypes.find(t => t.value == x)
  },

  subscriptionType(x) {
    return subscriptionTypes.find(t => t.value == x)
  },

  subscriptionStatus(subscription) {
    if (subscription.Discontinued == true) {
      return { label: "Stoppé", color: "red", icon: "mdi-cancel" };
    }
    else if (subscription.Paused) {
      return { label: "En pause", color: "grey", icon: "mdi-pause" };
    }
    else if (subscription.SubscriptionLimitDate && !date.isFuture(subscription.SubscriptionLimitDate)) {
      return { label: "Expiré", color: "red", icon: "mdi-timer-sand-empty" };
    }
    else if (subscription.Quantity <= 0) {
      return { label: "Épuisé", color: "grey", icon: "mdi-cancel" };
    }
    return { label: "Actif", color: "green", icon: "mdi-check" };
  },
}

export { subscriptionTypes, validityTypes, durationTypes, frequencies, frequenciesAlt, firstPurchaseModes }