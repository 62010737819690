// no one JS import

const initialState = () => ({
    filter: {

        preSelectFilterSelectedName: 'Aucun',

        /// //////////// General cdt to allow fonctionnalities

        conditionsSatisfied: true,

        /// ////////////

        toggle_multiple: [],
        second_toggle_multiple: [],

        /// //////////// Activities & activities'Families Picker :

        selectedActivities: [],
        selectedFamilies: [],

        /// //////////// Planning Saison Picker :

        saison: '2018',
        second_saison: '2019',

        /// //////////// Planning_Switch : "Date Mode" - "Season Mode" :

        saison_picker_checkbox: null,

        /// //////////// Switch : "Date Compare" - "Date du - au" :

        date_picker_checkbox: null,

        /// //////////// Date Picker "Du" et Date Picker "Au" :

        du_annee: null,
        du_mois: null,
        du_jour: null,

        au_annee: null,
        au_mois: null,
        au_jour: null,

        du_menu: false,
        du_picker: new Date().toISOString().substr(0, 10),

        au_menu: false,
        au_picker: new Date().toISOString().substr(0, 10),

        second_date_range_checkbox: null,

        second_du_annee: null,
        second_du_mois: null,
        second_du_jour: null,

        second_au_annee: null,
        second_au_mois: null,
        second_au_jour: null,

        second_du_menu: false,
        second_du_picker: new Date().toISOString().substr(0, 10),

        second_au_menu: false,
        second_au_picker: new Date().toISOString().substr(0, 10),

        /// //////////// Annee Picker 1 & 2 (param for data DL : send to backend) :

        annee: null,
        mois: null,
        jour: null,

        second_annee: null,
        second_mois: null,
        second_jour: null,

        /// //////////// Date Picker 1 (param for data displayed on screen) :

        year: false,
        month: false,
        day: false,

        picker: new Date().toISOString().substr(0, 10),
        picker_diplay: new Date().toISOString().substr(0, 10),

        menu: false,

        /// //////////// Date Picker 2 (param for data displayed on screen) :

        second_year: false,
        second_month: false,
        second_day: false,

        second_picker: new Date().toISOString().substr(0, 10),
        second_picker_diplay: new Date().toISOString().substr(0, 10),

        second_menu: false
    }
})

export default {
    state: initialState,
    getters: {
        statsFilterResaBilansPeriodiques: state => state.filter
    },
    actions: {
        /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        reset_stats_resa_bilans_periodiques (state) {
            const initial = initialState()
            Object.keys(initial).forEach(key => { state[key] = initial[key] })
        },

        // DATE INITIALE : Standard Périodes ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        getStatsActuDateBtnJour ({ commit }) {
            const monthsList = ['Aucun', 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
            if (!this.state.stats.resa.bilansperiodiques.filter.day) {
                // Btn Jour va s'enclencher
                commit('set_statsPickerDisplay', this.state.stats.resa.bilansperiodiques.filter.picker)
                // commit('set_statsPickerDisplay', "Jour");
            } else if (!this.state.stats.resa.bilansperiodiques.filter.year && !this.state.stats.resa.bilansperiodiques.filter.month) {
                // Btn Jour s'arrête
                // Btn Année et Mois pas enclenchés
                commit('set_statsPickerDisplay', 'Aucun filtre sur les données')
            } else if (this.state.stats.resa.bilansperiodiques.filter.year && !this.state.stats.resa.bilansperiodiques.filter.month) {
                // Btn Année enclenché mais pas Mois
                commit('set_statsPickerDisplay', parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[0], 10))
                // commit('set_statsPickerDisplay', "Année");
            } else if (!this.state.stats.resa.bilansperiodiques.filter.year && this.state.stats.resa.bilansperiodiques.filter.month) {
                // Btn Mois enclenché mais pas Année
                commit('set_statsPickerDisplay', monthsList[parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[1], 10)])
                // commit('set_statsPickerDisplay', "Mois");
            } else if (this.state.stats.resa.bilansperiodiques.filter.year && this.state.stats.resa.bilansperiodiques.filter.month) {
                // Btn Année et Mois enclenchés
                commit('set_statsPickerDisplay', parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[0], 10) + ' - ' + parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[1], 10))
                // commit('set_statsPickerDisplay', "Année + Mois");
            }
        },

        getStatsActuDateBtnMois ({ commit }) {
            const monthsList = ['Aucun', 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
            if (!this.state.stats.resa.bilansperiodiques.filter.day && !this.state.stats.resa.bilansperiodiques.filter.year && !this.state.stats.resa.bilansperiodiques.filter.month) {
                // Btn Mois va s'enclencher (aucun des 3 btn n'étaient enclechés)
                commit('set_statsPickerDisplay', monthsList[parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[1], 10)])
                // commit('set_statsPickerDisplay', "Mois");
            } else if (!this.state.stats.resa.bilansperiodiques.filter.day && this.state.stats.resa.bilansperiodiques.filter.year && !this.state.stats.resa.bilansperiodiques.filter.month) {
                // Btn Mois va s'enclencher (seul btn Année est encleché)
                commit('set_statsPickerDisplay', parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[0], 10) + ' - ' + parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[1], 10))
                // commit('set_statsPickerDisplay', "Année + Mois");
            } else if (!this.state.stats.resa.bilansperiodiques.filter.day && !this.state.stats.resa.bilansperiodiques.filter.year && this.state.stats.resa.bilansperiodiques.filter.month) {
                // Btn Mois va s'arrêter (aucun des 2 autres btn n'étaient enclechés)
                commit('set_statsPickerDisplay', 'Aucun filtre sur les données')
            } else if (!this.state.stats.resa.bilansperiodiques.filter.day && this.state.stats.resa.bilansperiodiques.filter.year && this.state.stats.resa.bilansperiodiques.filter.month) {
                // Btn Mois va s'arrêter (seul btn Année est encleché)
                commit('set_statsPickerDisplay', parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[0], 10))
                // commit('set_statsPickerDisplay', "Année");
            }
        },

        getStatsActuDateBtnAnnee ({ commit }) {
            const monthsList = ['Aucun', 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
            if (!this.state.stats.resa.bilansperiodiques.filter.day && !this.state.stats.resa.bilansperiodiques.filter.year && !this.state.stats.resa.bilansperiodiques.filter.month) {
                // Btn Annee va s'enclencher (aucun des 3 btn n'étaient enclechés)
                commit('set_statsPickerDisplay', parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[0], 10))
                // commit('set_statsPickerDisplay', "Année");
            } else if (!this.state.stats.resa.bilansperiodiques.filter.day && !this.state.stats.resa.bilansperiodiques.filter.year && this.state.stats.resa.bilansperiodiques.filter.month) {
                // Btn Année va s'enclencher (seul btn Mois est encleché)
                commit('set_statsPickerDisplay', parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[0], 10) + ' - ' + parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[1], 10))
                // commit('set_statsPickerDisplay', "Année + Mois");
            } else if (!this.state.stats.resa.bilansperiodiques.filter.day && this.state.stats.resa.bilansperiodiques.filter.year && !this.state.stats.resa.bilansperiodiques.filter.month) {
                // Btn Année va s'arrêter (aucun des 2 autres btn n'étaient enclechés)
                commit('set_statsPickerDisplay', 'Aucun filtre sur les données')
            } else if (!this.state.stats.resa.bilansperiodiques.filter.day && this.state.stats.resa.bilansperiodiques.filter.year && this.state.stats.resa.bilansperiodiques.filter.month) {
                // Btn Année va s'arrêter (seul btn Mois est encleché)
                commit('set_statsPickerDisplay', monthsList[parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[1], 10)])
                // commit('set_statsPickerDisplay', "Mois");
            }
        },

        getStatsActuDateBtnValidate ({ commit }) {
            const monthsList = ['Aucun', 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
            if (this.state.stats.resa.bilansperiodiques.filter.day) {
                commit('set_statsPickerDisplay', this.state.stats.resa.bilansperiodiques.filter.picker)
            } else if (this.state.stats.resa.bilansperiodiques.filter.year && !this.state.stats.resa.bilansperiodiques.filter.month) {
                commit('set_statsPickerDisplay', parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[0], 10))
            } else if (!this.state.stats.resa.bilansperiodiques.filter.year && this.state.stats.resa.bilansperiodiques.filter.month) {
                commit('set_statsPickerDisplay', monthsList[parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[1], 10)])
                // commit('set_statsPickerDisplay', "Mois : " + parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[1], 10));
            } else if (this.state.stats.resa.bilansperiodiques.filter.year && this.state.stats.resa.bilansperiodiques.filter.month) {
                commit('set_statsPickerDisplay', +parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[0], 10) + ' - ' + +parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[1], 10))
            }
        },

        // DATE TO COMPARE //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        getStatsSecondActuDateBtnJour ({ commit }) {
            const monthsList = ['Aucun', 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
            // Btn Jour va s'enclencher
            if (!this.state.stats.resa.bilansperiodiques.filter.second_day) {
                // commit('set_statsSecondPickerDisplay', "Jour");
                commit('set_statsSecondPickerDisplay', this.state.stats.resa.bilansperiodiques.filter.second_picker)
            } else if (!this.state.stats.resa.bilansperiodiques.filter.second_year && !this.state.stats.resa.bilansperiodiques.filter.second_month) {
                // Btn Jour s'arrête
                // Btn Année et Mois pas enclenchés
                commit('set_statsSecondPickerDisplay', 'Aucun filtre sur les données')
            } else if (this.state.stats.resa.bilansperiodiques.filter.second_year && !this.state.stats.resa.bilansperiodiques.filter.second_month) {
                // Btn Année enclenché mais pas Mois
                commit('set_statsSecondPickerDisplay', parseInt(this.state.stats.resa.bilansperiodiques.filter.second_picker.split('-')[0], 10))
                // commit('set_statsSecondPickerDisplay', "Année");
            } else if (!this.state.stats.resa.bilansperiodiques.filter.second_year && this.state.stats.resa.bilansperiodiques.filter.second_month) {
                // Btn Mois enclenché mais pas Année
                commit('set_statsSecondPickerDisplay', monthsList[parseInt(this.state.stats.resa.bilansperiodiques.filter.second_picker.split('-')[1], 10)])
                // commit('set_statsSecondPickerDisplay', "Mois");
            } else if (this.state.stats.resa.bilansperiodiques.filter.second_year && this.state.stats.resa.bilansperiodiques.filter.second_month) {
                // Btn Année et Mois enclenchés
                commit('set_statsSecondPickerDisplay', parseInt(this.state.stats.resa.bilansperiodiques.filter.second_picker.split('-')[0], 10) + ' - ' + parseInt(this.state.stats.resa.bilansperiodiques.filter.second_picker.split('-')[1], 10))
                // commit('set_statsSecondPickerDisplay', "Année + Mois");
            }
        },

        getStatsSecondActuDateBtnMois ({ commit }) {
            const monthsList = ['Aucun', 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
            if (!this.state.stats.resa.bilansperiodiques.filter.second_day && !this.state.stats.resa.bilansperiodiques.filter.second_year && !this.state.stats.resa.bilansperiodiques.filter.second_month) {
                // Btn Mois va s'enclencher (aucun des 3 btn n'étaient enclechés)
                commit('set_statsSecondPickerDisplay', monthsList[parseInt(this.state.stats.resa.bilansperiodiques.filter.second_picker.split('-')[1], 10)])
                // commit('set_statsSecondPickerDisplay', "Mois");
            } else if (!this.state.stats.resa.bilansperiodiques.filter.second_day && this.state.stats.resa.bilansperiodiques.filter.second_year && !this.state.stats.resa.bilansperiodiques.filter.second_month) {
                // Btn Mois va s'enclencher (seul btn Année est encleché)
                commit('set_statsSecondPickerDisplay', parseInt(this.state.stats.resa.bilansperiodiques.filter.second_picker.split('-')[0], 10) + ' - ' + parseInt(this.state.stats.resa.bilansperiodiques.filter.second_picker.split('-')[1], 10))
                // commit('set_statsSecondPickerDisplay', "Année + Mois");
            } else if (!this.state.stats.resa.bilansperiodiques.filter.second_day && !this.state.stats.resa.bilansperiodiques.filter.second_year && this.state.stats.resa.bilansperiodiques.filter.second_month) {
                // Btn Mois va s'arrêter (aucun des 2 autres btn n'étaient enclechés)
                commit('set_statsSecondPickerDisplay', 'Aucun filtre sur les données')
            } else if (!this.state.stats.resa.bilansperiodiques.filter.second_day && this.state.stats.resa.bilansperiodiques.filter.second_year && this.state.stats.resa.bilansperiodiques.filter.second_month) {
                // Btn Mois va s'arrêter (seul btn Année est encleché)
                commit('set_statsSecondPickerDisplay', parseInt(this.state.stats.resa.bilansperiodiques.filter.second_picker.split('-')[0], 10))
                // commit('set_statsSecondPickerDisplay', "Année");
            }
        },

        getStatsSecondActuDateBtnAnnee ({ commit }) {
            const monthsList = ['Aucun', 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
            if (!this.state.stats.resa.bilansperiodiques.filter.second_day && !this.state.stats.resa.bilansperiodiques.filter.second_year && !this.state.stats.resa.bilansperiodiques.filter.second_month) {
                // Btn Annee va s'enclencher (aucun des 3 btn n'étaient enclechés)
                commit('set_statsSecondPickerDisplay', parseInt(this.state.stats.resa.bilansperiodiques.filter.second_picker.split('-')[0], 10))
                // commit('set_statsSecondPickerDisplay', "Année");
            } else if (!this.state.stats.resa.bilansperiodiques.filter.second_day && !this.state.stats.resa.bilansperiodiques.filter.second_year && this.state.stats.resa.bilansperiodiques.filter.second_month) {
                // Btn Année va s'enclencher (seul btn Mois est encleché)
                commit('set_statsSecondPickerDisplay', parseInt(this.state.stats.resa.bilansperiodiques.filter.second_picker.split('-')[0], 10) + ' - ' + parseInt(this.state.stats.resa.bilansperiodiques.filter.second_picker.split('-')[1], 10))
                // commit('set_statsSecondPickerDisplay', "Année + Mois");
            } else if (!this.state.stats.resa.bilansperiodiques.filter.second_day && this.state.stats.resa.bilansperiodiques.filter.second_year && !this.state.stats.resa.bilansperiodiques.filter.second_month) {
                // Btn Année va s'arrêter (aucun des 2 autres btn n'étaient enclechés)
                commit('set_statsSecondPickerDisplay', 'Aucun filtre sur les données')
            } else if (!this.state.stats.resa.bilansperiodiques.filter.second_day && this.state.stats.resa.bilansperiodiques.filter.second_year && this.state.stats.resa.bilansperiodiques.filter.second_month) {
                // Btn Année va s'arrêter (seul btn Mois est encleché)
                commit('set_statsSecondPickerDisplay', monthsList[parseInt(this.state.stats.resa.bilansperiodiques.filter.second_picker.split('-')[1], 10)])
                // commit('set_statsSecondPickerDisplay', "Mois");
            }
        },

        getStatsSecondActuDateBtnValidate ({ commit }) {
            const monthsList = ['Aucun', 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
            if (this.state.stats.resa.bilansperiodiques.filter.second_day) {
                commit('set_statsSecondPickerDisplay', this.state.stats.resa.bilansperiodiques.filter.second_picker)
            } else if (this.state.stats.resa.bilansperiodiques.filter.second_year && !this.state.stats.resa.bilansperiodiques.filter.second_month) {
                commit('set_statsSecondPickerDisplay', parseInt(this.state.stats.resa.bilansperiodiques.filter.second_picker.split('-')[0], 10))
            } else if (!this.state.stats.resa.bilansperiodiques.filter.second_year && this.state.stats.resa.bilansperiodiques.filter.second_month) {
                // commit('set_statsSecondPickerDisplay', "Mois : " + parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[1], 10));
                commit('set_statsSecondPickerDisplay', monthsList[parseInt(this.state.stats.resa.bilansperiodiques.filter.second_picker.split('-')[1], 10)])
            } else if (this.state.stats.resa.bilansperiodiques.filter.second_year && this.state.stats.resa.bilansperiodiques.filter.second_month) {
                commit('set_statsSecondPickerDisplay', +parseInt(this.state.stats.resa.bilansperiodiques.filter.second_picker.split('-')[0], 10) + ' - ' + +parseInt(this.state.stats.resa.bilansperiodiques.filter.second_picker.split('-')[1], 10))
            }
        },

        // DOWNLOAD DATA //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        getStatsActuDateRange ({ commit }) {
            // DU var : annee, mois, jour
            commit('set_statsDuAnnee', parseInt(this.state.stats.resa.bilansperiodiques.filter.du_picker.split('-')[0], 10))
            commit('set_statsDuMois', parseInt(this.state.stats.resa.bilansperiodiques.filter.du_picker.split('-')[1], 10))
            commit('set_statsDuJour', parseInt(this.state.stats.resa.bilansperiodiques.filter.du_picker.split('-')[2], 10))
            // AU var : annee, mois, jour
            commit('set_statsAuAnnee', parseInt(this.state.stats.resa.bilansperiodiques.filter.au_picker.split('-')[0], 10))
            commit('set_statsAuMois', parseInt(this.state.stats.resa.bilansperiodiques.filter.au_picker.split('-')[1], 10))
            commit('set_statsAuJour', parseInt(this.state.stats.resa.bilansperiodiques.filter.au_picker.split('-')[2], 10))

            if (this.state.stats.resa.bilansperiodiques.filter.second_date_range_checkbox) {
                // SECOND DU var : annee, mois, jour
                commit('set_statsSecondDuAnnee', parseInt(this.state.stats.resa.bilansperiodiques.filter.second_du_picker.split('-')[0], 10))
                commit('set_statsSecondDuMois', parseInt(this.state.stats.resa.bilansperiodiques.filter.second_du_picker.split('-')[1], 10))
                commit('set_statsSecondDuJour', parseInt(this.state.stats.resa.bilansperiodiques.filter.second_du_picker.split('-')[2], 10))
                // SECOND AU var : annee, mois, jour
                commit('set_statsSecondAuAnnee', parseInt(this.state.stats.resa.bilansperiodiques.filter.second_au_picker.split('-')[0], 10))
                commit('set_statsSecondAuMois', parseInt(this.state.stats.resa.bilansperiodiques.filter.second_au_picker.split('-')[1], 10))
                commit('set_statsSecondAuJour', parseInt(this.state.stats.resa.bilansperiodiques.filter.second_au_picker.split('-')[2], 10))
            }
        },

        getStatsActuDate ({ commit }) {
            // var annee, mois, jour
            if (this.state.stats.resa.bilansperiodiques.filter.year) {
                commit('set_statsAnnee', parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[0], 10))
            } else {
                commit('set_statsAnnee', -1)
            }
            if (this.state.stats.resa.bilansperiodiques.filter.month) {
                commit('set_statsMois', parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[1], 10))
            } else {
                commit('set_statsMois', -1)
            }
            if (this.state.stats.resa.bilansperiodiques.filter.day) {
                commit('set_statsAnnee', parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[0], 10))
                commit('set_statsMois', parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[1], 10))
                commit('set_statsJour', parseInt(this.state.stats.resa.bilansperiodiques.filter.picker.split('-')[2], 10))
            } else {
                commit('set_statsJour', -1)
            }

            // SECOND var annee, mois, jour
            if (this.state.stats.resa.bilansperiodiques.filter.second_year) {
                commit('set_statsSecondAnnee', parseInt(this.state.stats.resa.bilansperiodiques.filter.second_picker.split('-')[0], 10))
            } else {
                commit('set_statsSecondAnnee', -1)
            }
            if (this.state.stats.resa.bilansperiodiques.filter.second_month) {
                commit('set_statsSecondMois', parseInt(this.state.stats.resa.bilansperiodiques.filter.second_picker.split('-')[1], 10))
            } else {
                commit('set_statsSecondMois', -1)
            }
            if (this.state.stats.resa.bilansperiodiques.filter.second_day) {
                commit('set_statsSecondAnnee', parseInt(this.state.stats.resa.bilansperiodiques.filter.second_picker.split('-')[0], 10))
                commit('set_statsSecondMois', parseInt(this.state.stats.resa.bilansperiodiques.filter.second_picker.split('-')[1], 10))
                commit('set_statsSecondJour', parseInt(this.state.stats.resa.bilansperiodiques.filter.second_picker.split('-')[2], 10))
            } else {
                commit('set_statsSecondJour', -1)
            }
        }
    },
    mutations: {
        /// //////////////////////////////////////////////////////////////////////
        set_statPreSelectFilterSelectedName (state, val) {
            state.filter.preSelectFilterSelectedName = val
        },
        /// //////////////////////////////////////////////////////////////////////
        // Date Switch : "Season Mode" - "Standard Period"
        set_statsSaisonPickerCheckbox (state, val) {
            state.filter.saison_picker_checkbox = val
        },
        // Date Switch : "Date Range" - "Standard Period"
        set_statsDatePickerCheckbox (state, val) {
            state.filter.date_picker_checkbox = val
        },
        // Do some comparisons
        set_statsSecondDateRangeCheckbox (state, val) {
            state.filter.second_date_range_checkbox = val
        },
        /// //////////////////////////////////////////////////////////////////////
        // Saison : Values 1 : in order to data downlaod
        set_statsSaison (state, val) {
            state.filter.saison = val
        },
        // Saison : Values 2 : in order to data downlaod
        set_statsSecondSaison (state, val) {
            state.filter.second_saison = val
        },
        /// //////////////////////////////////////////////////////////////////////
        // Date : Values "DU" : in order to data downlaod
        set_statsDuAnnee (state, val) {
            state.filter.du_annee = val
        },
        set_statsDuMois (state, val) {
            state.filter.du_mois = val
        },
        set_statsDuJour (state, val) {
            state.filter.du_jour = val
        },
        set_statsSecondDuAnnee (state, val) {
            state.filter.second_du_annee = val
        },
        set_statsSecondDuMois (state, val) {
            state.filter.second_du_mois = val
        },
        set_statsSecondDuJour (state, val) {
            state.filter.second_du_jour = val
        },
        // Date : Values "AU" : in order to data downlaod
        set_statsAuAnnee (state, val) {
            state.filter.au_annee = val
        },
        set_statsAuMois (state, val) {
            state.filter.au_mois = val
        },
        set_statsAuJour (state, val) {
            state.filter.au_jour = val
        },
        set_statsSecondAuAnnee (state, val) {
            state.filter.second_au_annee = val
        },
        set_statsSecondAuMois (state, val) {
            state.filter.second_au_mois = val
        },
        set_statsSecondAuJour (state, val) {
            state.filter.second_au_jour = val
        },
        // Date : Pickers : "DU" et "AU"
        set_statsDuPicker (state, val) {
            state.filter.du_picker = val
        },
        set_statsAuPicker (state, val) {
            state.filter.au_picker = val
        },
        set_statsSecondDuPicker (state, val) {
            state.filter.second_du_picker = val
        },
        set_statsSecondAuPicker (state, val) {
            state.filter.second_au_picker = val
        },
        /// //////////////////////////////////////////////////////////////////////
        // Date : Values 1 : in order to data downlaod
        set_statsAnnee (state, val) {
            state.filter.annee = val
        },
        set_statsMois (state, val) {
            state.filter.mois = val
        },
        set_statsJour (state, val) {
            state.filter.jour = val
        },
        // Date : Values 2 : in order to data downlaod
        set_statsSecondAnnee (state, val) {
            state.filter.second_annee = val
        },
        set_statsSecondMois (state, val) {
            state.filter.second_mois = val
        },
        set_statsSecondJour (state, val) {
            state.filter.second_jour = val
        },
        // Date : Pickers
        set_statsPicker (state, val) {
            state.filter.picker = val
        },
        set_statsSecondPicker (state, val) {
            state.filter.second_picker = val
        },
        // Date : Pickers Display
        set_statsPickerDisplay (state, val) {
            state.filter.picker_display = val
        },
        set_statsSecondPickerDisplay (state, val) {
            state.filter.second_picker_display = val
        }
    }
}
