import ApiAuth from '~/api/auth'
import ApiCtm from '~/api/ctm'
import ApiProducts from '~/api/products'
import ApiAreas from '~/api/areas'
import ApiWeekTypes from '~/api/weekTypes'
import ApiAccessControl from '~/api/accessControl'
import _ from 'lodash'

const initialState = () => ({
    auth: {
        status: 'idle',
        token: null,
        user: null
    },
    tsBoxes: [],
    products: [],
    areas: [],
    weekTypes: [],
    accessTimeslots: [],
    accessTimeslotsLoading: false,
    navigation_drawer_permanent: false,
    expandMainMenu: null, // null, 'mini' or 'full'
    forbidden: false
})

export default {
    state: initialState,
    getters: {
        isLoggedIn: state => !!state.auth.token && !!state.auth.user,
        authStatus: state => state.auth.status,
        authToken: state => state.auth.token,
        authUser: state => state.auth.user,
        hasRight: state => rightName => state.auth.user && state.auth.user.Permissions && state.auth.user.Permissions.findIndex(el => el === rightName) !== -1,
        hasRights: state => rightNames => state.auth.user && state.auth.user.Permissions && rightNames.every(el => state.auth.user.Permissions.includes(el)),
        dimsBackofficeUrl: state => state.auth.dimsBackofficeUrl,
        forbidden: state => state.forbidden,
        vcrUrl: state => state.auth.vcrUrl,
        tsBoxes: state => state.tsBoxes,
        products: state => state.products,
        getProductByID: (state) => (id) => {
            return state.products.find(p => p.ID === id)
        },
        getProductLabel: (state) => (id) => {
            const product = state.products.find(p => p.ID === id)
            return product ? product.Label.FR : ''
        },
        getProductLabelShort: (state) => (id) => {
            const product = state.products.find(p => p.ID === id)
            return product ? product.LabelShort.FR : ''
        },
        families: state => state.families,
        familiesById: state => _.keyBy(state.families, 'ID'),
        familiesTree (state) {
            const out = []

            const recurseGetParents = function (id) {
                for (let i = 0; i < state.families.length; ++i) {
                    const fam = state.families[i]
                    if (fam.Subfamilies && fam.Subfamilies.includes(id)) {
                        return recurseGetParents(fam.ID) + fam.Label.FR + ' / '
                    }
                }

                return ''
            }

            if (state.families) {
                for (let i = 0; i < state.families.length; ++i) {
                    const fam = state.families[i]
                    fam.FullPathLabel = recurseGetParents(fam.ID) + fam.Label.FR

                    out.push(fam)
                }
            }

            out.sort(function (a, b) {
                if (a.FullPathLabel === b.FullPathLabel) {
                    return 0
                } else if (a.FullPathLabel < b.FullPathLabel) {
                    return -1
                } else {
                    return 1
                }
            })

            return out
        },
        areas: state => state.areas,
        weekTypes: state => state.weekTypes,
        accessTimeslots: state => state.accessTimeslots,
        accessTimeslotsLoading: state => state.accessTimeslotsLoading,
        navigationDrawerState: state => state.navigation_drawer_permanent,
        expandMainMenu: state => state.expandMainMenu
    },
    actions: {
        login ({ commit }, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')

                ApiAuth.login(user)
                    .then(res => {
                        if (res.data.Result === 0) {
                            const token = res.data.Token
                            const userInfo = res.data.User
                            const dimsBackofficeUrl = res.data.DimsBackofficeURL
                            const vcrUrl = res.data.VcrURL
                            commit('auth_success', { token, user: userInfo, dimsBackofficeUrl, vcrUrl })
                        }

                        // Forward in any case (e.g. for 2FA)
                        resolve(res)
                    })
                    .catch(err => {
                        commit('auth_error', err)
                        reject(err)
                    })
            })
        },

        autologin ({ commit }, token) {
            return new Promise((resolve, reject) => {
                commit('auth_request')

                ApiAuth.loginToken(token)
                    .then(res => {
                        console.warn('loginToken result success:', res.data)
                        const authToken = res.data.Token
                        const user = res.data.User
                        const dimsBackofficeUrl = res.data.DimsBackofficeURL
                        const vcrUrl = res.data.VcrURL
                        commit('auth_success', { token: authToken, user, dimsBackofficeUrl, vcrUrl })
                        resolve(res)
                    })
                    .catch(err => {
                        commit('auth_error', err)
                        reject(err)
                    })
            })
        },

        logout ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiAuth.logout().then(res => {
                    commit('logout')
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getTurnstiles ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiCtm.getTurnstiles().then(res => {
                    commit('set_turnstiles_boxes', res.data || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getProducts ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiProducts.getProducts({ IsSubscription: true }).then(res => {
                    commit('set_products', res.data || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getFamilies ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiProducts.getFamilies({ State: [true] }).then(res => {
                    commit('set_families', res.data || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getAreas ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiAreas.getAreas().then(res => {
                    commit('set_areas', res.data || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        updateArea ({ commit }, area) {
            return new Promise((resolve, reject) => {
                ApiAreas.updateArea(area).then(res => {
                    let found = false

                    for (let i = 0; i < this.state.main.areas.length; ++i) {
                        const a = this.state.main.areas[i]
                        if (a.ID === area.ID) {
                            this.state.main.areas[i] = area
                            commit('set_areas', this.state.main.areas)
                            found = true
                            break
                        }
                    }

                    if (!found) {
                        this.state.main.areas.push(area)
                        commit('set_areas', this.state.main.areas)
                    }

                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        createArea ({ commit }, area) {
            return new Promise((resolve, reject) => {
                ApiAreas.createArea(area).then(res => {
                    this.state.main.areas.push(area)
                    commit('set_areas', this.state.main.areas)
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getWeekTypes ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiWeekTypes.getWeekTypes().then(res => {
                    commit('set_weekTypes', res.data || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getAccessTimeslots ({ commit }, filters) {
            commit('set_accessTimeslotsLoading', true)
            return new Promise((resolve, reject) => {
                ApiAccessControl.getTimeslots(filters).then(res => {
                    commit('set_accessTimeslots', res.data || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        }
    },
    mutations: {
        reset_main (state) {
            const initial = initialState()
            Object.keys(initial).forEach(key => { state[key] = initial[key] })
        },
        auth_request (state) {
            state.auth.status = 'loading'
        },
        set_auth_status (state, val) {
            state.auth.status = val
        },
        auth_success (state, val) {
            state.auth.status = 'success'
            state.auth.token = val.token
            state.auth.user = val.user
            state.auth.dimsBackofficeUrl = val.dimsBackofficeUrl
            state.auth.vcrUrl = val.vcrUrl
            state.navigation_drawer_permanent = true
        },
        auth_error (state) {
            state.auth.status = 'error'
            state.auth.token = null
            state.auth.user = null
            state.navigation_drawer_permanent = false
        },
        set_auth_permissions (state, perms) {
            state.auth.user.Permissions = perms
        },
        logout (state) {
            state.auth.status = 'idle'
            state.auth.token = null
            state.auth.user = null
            state.navigation_drawer_permanent = false
        },
        set_forbidden (state, val) {
            state.forbidden = val
        },
        set_turnstiles_boxes (state, val) {
            state.tsBoxes = val
        },
        set_products (state, val) {
            state.products = val
        },
        set_families (state, val) {
            state.families = val
        },
        set_areas (state, val) {
            state.areas = val
        },
        set_weekTypes (state, val) {
            state.weekTypes = val
        },
        set_accessTimeslots (state, val) {
            state.accessTimeslots = val
            state.accessTimeslotsLoading = false
        },
        set_accessTimeslotsLoading (state, val) {
            state.accessTimeslotsLoading = val
        },
        setExpandMainMenu (state, val) {
            state.expandMainMenu = val
        }
    }
}
