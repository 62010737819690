import ui, { subscriptionTypes, validityTypes, durationTypes, frequencies, frequenciesAlt, firstPurchaseModes } from '../ui/subscriptions'
import helpers from '../helpers/subscriptions'


export default {
    data: () => ({
        subscriptionTypes,
        validityTypes, 
        durationTypes, 
        frequencies,
        frequenciesAlt,
        firstPurchaseModes
    }),
    methods: {
        ...ui,
        ...helpers
    }
}