import Vue from 'vue'
import LockersApi from '@/api/lockers.js'

const initialState = () => ({
    gateways: [],
    lockerTypes: [],
    newIncidentsCounter: 0,
    locationsTree: [],
    selectedTreeLocation: [],
    establishmentsWithLockersStats: [],
    areasWithLockersStats: [],
    blocksWithLockersStats: []
})

export default {
    state: initialState,

    getters: {
        gateways: state => state.gateways,
        lockerTypes: state => state.lockerTypes,
        newIncidentsCounter: state => state.newIncidentsCounter,
        establishmentsWithLockersStats: state => state.establishmentsWithLockersStats,
        areasWithLockersStats: state => state.areasWithLockersStats,
        blocksWithLockersStats: state => state.blocksWithLockersStats,
        locationsTree: state => state.locationsTree,
        selectedTreeLocation: state => state.selectedTreeLocation
    },

    mutations: {
        reset_lockers (state) {
            const initial = initialState()
            Object.keys(initial).forEach(key => { state[key] = initial[key] })
        },

        set_gateways (state, gateways) {
            state.gateways = gateways
        },
        update_gateways (state, gateway) {
            const updatedGatewayIndex = state.gateways.findIndex(
                gtw => gtw.ID === gateway.ID
            )
            if (updatedGatewayIndex === -1) {
                state.gateways.push(gateway)
            } else {
                Vue.set(state.gateways, updatedGatewayIndex, gateway)
            }
        },

        set_lockerTypes (state, types) {
            state.lockerTypes = types
        },

        reset_locker_incidents (state) {
            state.newIncidentsCounter = 0
        },

        new_locker_incident (state) {
            state.newIncidentsCounter++
        },

        set_establishments_with_lockers_stats (state, establishments) {
            state.establishmentsWithLockersStats = establishments
        },

        update_establishment_with_lockers_stats (state, establishment) {
            const updatedEstablishmentIndex = state.establishmentsWithLockersStats.findIndex(
                e => e.ID === establishment.ID
            )
            if (updatedEstablishmentIndex === -1) {
                state.establishmentsWithLockersStats.push(establishment)
            } else {
                Vue.set(state.establishmentsWithLockersStats, updatedEstablishmentIndex, establishment)
            }
        },

        set_areas_with_lockers_stats (state, areas) {
            state.areasWithLockersStats = areas
        },

        update_area_with_lockers_stats (state, area) {
            const updatedAreakIndex = state.areasWithLockersStats.findIndex(
                a => a.ID === area.ID
            )
            if (updatedAreakIndex === -1) {
                state.areasWithLockersStats.push(area)
            } else {
                Vue.set(state.areasWithLockersStats, updatedAreakIndex, area)
            }
        },

        set_blocks_with_lockers_stats (state, blocks) {
            state.blocksWithLockersStats = blocks
        },

        update_block_with_lockers_stats (state, block) {
            const updatedBlockIndex = state.blocksWithLockersStats.findIndex(
                b => b.ID === block.ID
            )
            if (updatedBlockIndex === -1) {
                state.blocksWithLockersStats.push(block)
            } else {
                Vue.set(state.blocksWithLockersStats, updatedBlockIndex, block)
            }
        },

        set_locations_tree (state, tree) {
            state.locationsTree = tree
        },

        set_selected_tree_location (state, location) {
            state.selectedTreeLocation = location
        }
    },

    actions: {
        getGateways ({ commit }) {
            return new Promise((resolve, reject) => {
                LockersApi.getLockerGateways()
                    .then(res => {
                        commit('set_gateways', res.data)
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        onGatewayUpdate ({ commit }, gateway) {
            commit('update_gateways', gateway)
        },

        getLockers ({ commit }, filters) {
            return new Promise((resolve, reject) => {
                LockersApi.getLockers({ ...filters })
                    .then(res => {
                        commit('set_lockers', res.data)
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        onLockerUpdate ({ commit }, locker) {
            commit('update_lockers', locker)
        },

        getLockerTypes ({ commit }) {
            return new Promise((resolve, reject) => {
                LockersApi.getLockersTypes()
                    .then(res => {
                        commit('set_lockerTypes', res.data)
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        onNewLockerIncident ({ commit }) {
            commit('new_locker_incident')
        },

        onLockerIncidentsViewed ({ commit }) {
            commit('reset_locker_incidents')
        },

        loadLocationsStats ({ commit }) {
            return new Promise((resolve, reject) => {
                LockersApi.getEstablishmentsWithLockersStats()
                    .then(res => {
                        commit('set_establishments_with_lockers_stats', res.data)
                        LockersApi.getAreasWithLockersStats()
                            .then(res => {
                                commit('set_areas_with_lockers_stats', res.data)
                                LockersApi.getBlocksWithLockersStats()
                                    .then(res => {
                                        commit('set_blocks_with_lockers_stats', res.data)
                                        resolve('ok')
                                    }).catch(err => {
                                        reject(err)
                                    })
                            }).catch(err => {
                                reject(err)
                            })
                    }).catch(err => {
                        reject(err)
                    })
            })
        },

        updateLocationsStats ({ commit }, { establishmentID, areaID, blockID }) {
            return new Promise((resolve, reject) => {
                const concurrentLoad = [
                    LockersApi.getEstablishmentWithLockersStats(establishmentID)
                        .then(res => {
                            commit('update_establishment_with_lockers_stats', res.data)
                        }).catch(err => {
                            reject(err)
                        }),
                    LockersApi.getAreaWithLockersStats(areaID)
                        .then(res => {
                            commit('update_area_with_lockers_stats', res.data)
                        }).catch(err => {
                            reject(err)
                        }),
                    LockersApi.getBlockWithLockersStats(blockID)
                        .then(res => {
                            commit('update_block_with_lockers_stats', res.data)
                        }).catch(err => {
                            reject(err)
                        })
                ]

                Promise.all(concurrentLoad).then(() => {
                    resolve('ok')
                })
            })
        }
    }
}
