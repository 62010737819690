export default [
    {
        path: '/kiosks',
        name: 'kiosks-index',
        meta: {
            title: 'Bornes'
        },
        component: () => import('@/views/kiosks/Index.vue')
    }
]
