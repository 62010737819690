export default [
    {
        path: '/lockers',
        name: 'lockers-index',
        meta: {
            title: 'Casiers'
        },
        redirect: { name: 'lockers-manager' },
        component: () => import('@/views/lockers/Index.vue'),
        children: [
            {
                path: 'dashboard',
                name: 'lockers-dashboard',
                meta: {
                    title: 'Tableau de bord'
                },
                component: () => import('@/views/lockers/dashboard/LockersDashboard.vue')
            },
            // BOOKINGS
            {
                path: 'bookings',
                name: 'lockers-bookings',
                meta: {
                    title: 'Réservations'
                },
                component: () => import('@/views/lockers/bookings/LockersBookings.vue')
            },
            {
                path: 'bookings/new-booking-export-mode',
                name: 'lockers-new-booking-export-mode',
                meta: {
                    title: 'Nouvelle réservation'
                },
                component: () => import('@/views/lockers/bookings/NewBookingExpertMode.vue')
            },
            {
                path: 'bookings/new-booking-guided-mode',
                name: 'lockers-new-booking-guided-mode',
                meta: {
                    title: 'Nouvelle réservation'
                },
                component: () => import('@/views/lockers/bookings/NewBookingGuidedMode.vue')
            },
            // MANAGER
            {
                path: 'manager',
                name: 'lockers-manager',
                meta: {
                    title: 'Gérer les casiers'
                },
                component: () => import('@/views/lockers/manager/Index.vue'),
                children: [
                    {
                        path: 'establishment/:id',
                        name: 'lockers-establishment-details',
                        meta: {
                            title: "Détail de l'établissement"
                        },
                        props: true,
                        component: () => import('@/views/lockers/manager/EstablishmentDetails.vue')
                    }, {
                        path: 'area/:id',
                        name: 'lockers-area-details',
                        meta: {
                            title: 'Détail de la zone'
                        },
                        props: true,
                        component: () => import('@/views/lockers/manager/AreaDetails.vue')
                    }, {
                        path: 'block/:id',
                        name: 'lockers-block-details',
                        meta: {
                            title: 'Détail du bloc'
                        },
                        props: true,
                        component: () => import('@/views/lockers/manager/BlockDetails.vue')
                    },
                    {
                        path: 'locker/:id',
                        name: 'lockers-locker-details',
                        meta: {
                            title: 'Détail du casier'
                        },
                        props: true,
                        component: () => import('@/views/lockers/manager/LockerDetails.vue')
                    }
                ]
            },
            // INCIDENTS
            {
                path: 'incidents',
                name: 'lockers-incidents',
                meta: {
                    title: 'Gérer les incidents'
                },
                component: () => import('@/components/lockers/incidents/LockersIncidents.vue')
            },
            // GATEWAYS
            {
                path: 'gateways',
                name: 'lockers-gateways',
                meta: {
                    title: 'Gérer les passerelles'
                },
                component: () => import('@/views/lockers/gateways/GatewaysManager.vue')
            }
        ]
    }
]
