import Consts from '../consts'

const daysOfWeek = [
    { text: 'Lundi', value: '1' },
    { text: 'Mardi', value: '2' },
    { text: 'Mercredi', value: '3' },
    { text: 'Jeudi', value: '4' },
    { text: 'Vendredi', value: '5' },
    { text: 'Samedi', value: '6' },
    { text: 'Dimanche', value: '0' }
]

const daysOfMonth = function () {
    const dom = []
    for (let i = 1; i <= 31; i++) {
        dom.push({ text: `${i}`, value: `${i}` })
    }
    return dom
}()

const exportFrequencies = [
    { text: 'Une fois par jour', value: 'daily' },
    { text: 'Une fois par semaine', value: 'weekly' },
    { text: 'Une fois par mois', value: 'monthly' }
]

export default {
    exportFrequency (x) {
        return exportFrequencies.find(elem => elem.value == x)
    },

    dayOfWeek (x) {
        return daysOfWeek.find(elem => elem.value == x)
    }
}

export {
    daysOfWeek,
    daysOfMonth,
    exportFrequencies
}