<template>
    <div layout="column" layout-align="center center" class="ma-3" style="min-height: 400px">
        <span class="text-h5 text-center mb-10">Vous essayez d'accéder à une page pour laquelle vous ne possédez pas assez de droits.</span>
        <v-icon size="100" class="mb-10">mdi-hand-back-right-off-outline</v-icon>
        <v-btn color=primary @click="goBack()"><v-icon class="mr-3">mdi-arrow-left</v-icon>Retour</v-btn>
    </div>
</template>

<script>
export default {
    methods: {
        goBack () {
            if (window.history.length > 2) {
                this.$router.go(-1)
            } else {
                this.$router.push('/')
            }
        }
    }
}
</script>

<style>
</style>
