// moment
import moment from 'moment'

const initialState = () => ({
    filter: {

        preSelectFilterSelectedName: 'Aucun',

        /// //////////// General cdt to allow fonctionnalities

        conditionsSatisfied: true,

        /// ////////////

        toggle_multiple: [],
        second_toggle_multiple: [],

        // /////////////

        planning_selectedColorsForEventsPerformances: [],
        planning_selectedColorsForEventsEvolutions: [],

        /// //////////// Planning Activities & activities'Families :

        planning_selectedActivities: [],
        planning_selectedFamilies: [],

        /// //////////// Planning Threshold :

        planning_threshold_one: 25,
        planning_threshold_two: 50,
        planning_threshold_three: 75,

        /// //////////// Planning Time mode Picker :

        planning_timeModeSelected: 'Mode Saison',

        /// //////////// Planning Saison Picker :

        planning_saisonChoisie: moment().format('YYYY'),
        planning_second_saisonChoisie: moment().subtract(1, 'year').format('YYYY'),

        /// //////////// Planning Annee Picker :

        planning_anneeChoisissables: [],

        planning_anneeChoisie: 'Aucune',
        planning_second_anneeChoisie: 'Aucune',

        /// //////////// Planning Month Picker :

        planning_moisChoisi: 'Aucun',
        planning_second_moisChoisi: 'Aucun',

        /// //////////// Planning_Date Picker "Du" et Date Picker "Au" :

        planning_du_annee: null,
        planning_du_mois: null,
        planning_du_jour: null,

        planning_au_annee: null,
        planning_au_mois: null,
        planning_au_jour: null,

        planning_du_menu: false,
        planning_du_picker: new Date().toISOString().substr(0, 10),

        planning_au_menu: false,
        planning_au_picker: new Date().toISOString().substr(0, 10),

        planning_second_du_annee: null,
        planning_second_du_mois: null,
        planning_second_du_jour: null,

        planning_second_au_annee: null,
        planning_second_au_mois: null,
        planning_second_au_jour: null,

        planning_second_du_menu: false,
        planning_second_du_picker: new Date().toISOString().substr(0, 10),

        planning_second_au_menu: false,
        planning_second_au_picker: new Date().toISOString().substr(0, 10)
    }
})

export default {
    state: initialState,
    getters: {
        statsFilterResaPlannings: state => state.filter
    },
    actions: {
        /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        reset_stats_resa_plannings (state) {
            const initial = initialState()
            Object.keys(initial).forEach(key => { state[key] = initial[key] })
        },

        // DOWNLOAD DATA //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        getStatsActuDateRangePlanningCR ({ commit }) {
            // DU var : annee, mois, jour
            commit('set_statsPlanningCRDuAnnee', parseInt(this.state.stats.resa.plannings.filter.planning_du_picker.split('-')[0], 10))
            commit('set_statsPlanningCRDuMois', parseInt(this.state.stats.resa.plannings.filter.planning_du_picker.split('-')[1], 10))
            commit('set_statsPlanningCRDuJour', parseInt(this.state.stats.resa.plannings.filter.planning_du_picker.split('-')[2], 10))
            // AU var : annee, mois, jour
            commit('set_statsPlanningCRAuAnnee', parseInt(this.state.stats.resa.plannings.filter.planning_au_picker.split('-')[0], 10))
            commit('set_statsPlanningCRAuMois', parseInt(this.state.stats.resa.plannings.filter.planning_au_picker.split('-')[1], 10))
            commit('set_statsPlanningCRAuJour', parseInt(this.state.stats.resa.plannings.filter.planning_au_picker.split('-')[2], 10))

            // SECOND DU var : annee, mois, jour
            commit('set_statsPlanningCRSecondDuAnnee', parseInt(this.state.stats.resa.plannings.filter.planning_second_du_picker.split('-')[0], 10))
            commit('set_statsPlanningCRSecondDuMois', parseInt(this.state.stats.resa.plannings.filter.planning_second_du_picker.split('-')[1], 10))
            commit('set_statsPlanningCRSecondDuJour', parseInt(this.state.stats.resa.plannings.filter.planning_second_du_picker.split('-')[2], 10))
            // SECOND AU var : annee, mois, jour
            commit('set_statsPlanningCRSecondAuAnnee', parseInt(this.state.stats.resa.plannings.filter.planning_second_au_picker.split('-')[0], 10))
            commit('set_statsPlanningCRSecondAuMois', parseInt(this.state.stats.resa.plannings.filter.planning_second_au_picker.split('-')[1], 10))
            commit('set_statsPlanningCRSecondAuJour', parseInt(this.state.stats.resa.plannings.filter.planning_second_au_picker.split('-')[2], 10))
        }
    },
    mutations: {
        /// //////////////////////////////////////////////////////////////////////
        // Plannings CR : Events/Activities to display in function of booking rate, etc. :
        set_planning_selectedColorsForEventsPerformances (state, val) {
            state.filter.planning_selectedColorsForEventsPerformances = val
        },
        add_planning_selectedColorsForEventsPerformances (state, val) {
            state.filter.planning_selectedColorsForEventsPerformances.push(val)
        },
        // Plannings CR : Events/Activities to display in function of evolution between time periods :
        set_planning_selectedColorsForEventsEvolutions (state, val) {
            state.filter.planning_selectedColorsForEventsEvolutions = val
        },
        add_planning_selectedColorsForEventsEvolutions (state, val) {
            state.filter.planning_selectedColorsForEventsEvolutions.push(val)
        },
        /// //////////////////////////////////////////////////////////////////////
        // Plannings CR : Activities and Families :
        set_planning_selectedActivities (state, val) {
            state.filter.planning_selectedActivities = val
        },
        add_planning_selectedActivities (state, val) {
            state.filter.planning_selectedActivities.push(val)
        },

        set_planning_selectedFamilies (state, val) {
            state.filter.planning_selectedEvolutionsForEvents = val
        },
        add_planning_selectedFamilies (state, val) {
            state.filter.planning_selectedFamilies.push(val)
        },
        /// //////////////////////////////////////////////////////////////////////
        // Plannings CR : Thresholds :
        set_planning_threshold_one (state, val) {
            state.filter.planning_threshold_one = val
        },
        set_planning_threshold_two (state, val) {
            state.filter.planning_threshold_two = val
        },
        set_planning_threshold_three (state, val) {
            state.filter.planning_threshold_three = val
        },
        /// //////////////////////////////////////////////////////////////////////
        // Plannings CR : Time Mode Picker
        set_statPreSelectFilterSelectedName (state, val) {
            state.filter.preSelectFilterSelectedName = val
        },
        /// //////////////////////////////////////////////////////////////////////
        // Plannings CR : Années choisissables (où il y a des données stats précalculée)
        set_anneeChoisissables (state, val) {
            state.filter.planning_anneeChoisissables = val
        },
        add_anneeChoisissables (state, val) {
            state.filter.planning_anneeChoisissables.push(val)
        },
        /// //////////////////////////////////////////////////////////////////////
        // Plannings CR : Time Mode Picker
        set_statsPlanningCRTimeModeSelected (state, val) {
            state.filter.planning_timeModeSelected = val
        },
        /// //////////////////////////////////////////////////////////////////////
        // Plannings CR : Saison Pickers
        set_saisonChoisie (state, val) {
            state.filter.planning_saisonChoisie = val
        },
        set_second_saisonChoisie (state, val) {
            state.filter.planning_second_saisonChoisie = val
        },
        /// //////////////////////////////////////////////////////////////////////
        // Plannings CR : Date Pickers
        set_anneeChoisie (state, val) {
            state.filter.planning_anneeChoisie = val
        },
        set_moisChoisi (state, val) {
            state.filter.planning_moisChoisi = val
        },
        set_second_anneeChoisie (state, val) {
            state.filter.planning_second_anneeChoisie = val
        },
        set_second_moisChoisi (state, val) {
            state.filter.planning_second_moisChoisi = val
        },
        /// //////////////////////////////////////////////////////////////////////
        // Plannings CR : Date : Values "DU" : in order to data downlaod
        set_statsPlanningCRDuAnnee (state, val) {
            state.filter.planning_du_annee = val
        },
        set_statsPlanningCRDuMois (state, val) {
            state.filter.planning_du_mois = val
        },
        set_statsPlanningCRDuJour (state, val) {
            state.filter.planning_du_jour = val
        },
        set_statsPlanningCRSecondDuAnnee (state, val) {
            state.filter.planning_second_du_annee = val
        },
        set_statsPlanningCRSecondDuMois (state, val) {
            state.filter.planning_second_du_mois = val
        },
        set_statsPlanningCRSecondDuJour (state, val) {
            state.filter.planning_second_du_jour = val
        },
        // Plannings CR : Date : Values "AU" : in order to data downlaod
        set_statsPlanningCRAuAnnee (state, val) {
            state.filter.planning_au_annee = val
        },
        set_statsPlanningCRAuMois (state, val) {
            state.filter.planning_au_mois = val
        },
        set_statsPlanningCRAuJour (state, val) {
            state.filter.planning_au_jour = val
        },
        set_statsPlanningCRSecondAuAnnee (state, val) {
            state.filter.planning_second_au_annee = val
        },
        set_statsPlanningCRSecondAuMois (state, val) {
            state.filter.planning_second_au_mois = val
        },
        set_statsPlanningCRSecondAuJour (state, val) {
            state.filter.planning_second_au_jour = val
        },
        // Plannings CR : Date : Pickers : "DU" et "AU"
        set_statsPlanningCRDuPicker (state, val) {
            state.filter.planning_du_picker = val
        },
        set_statsPlanningCRAuPicker (state, val) {
            state.filter.planning_au_picker = val
        },
        set_statsPlanningCRSecondDuPicker (state, val) {
            state.filter.planning_second_du_picker = val
        },
        set_statsPlanningCRSecondAuPicker (state, val) {
            state.filter.planning_second_au_picker = val
        }
    }
}
