import ui, { turnstilesTypes, passingTypes, passingDirections, turnstilesStates, accessControlEventTypes } from '../ui/access-control'

export default {
    data: () => ({
        turnstilesTypes,
        passingTypes,
        passingDirections,
        turnstilesStates,
        accessControlEventTypes
    }),
    methods: {
        ...ui,
    }
}