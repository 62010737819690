export default [
    {
        path: '/business',
        name: 'business-index',
        redirect: { name: 'business-exports-financial' },
        meta: {
            title: 'Gestion commerciale'
        },
        component: () => import('~/views/business/Index.vue'),
        children: [
            {
                path: 'business-exports-exports-financial',
                name: 'business-exports-financial',
                meta: {
                    title: 'Exports financiers'
                },
                component: () => import('~/views/business/exports/Financial.vue')
            },
            {
                path: 'business-exports-families-daily',
                name: 'business-families-daily',
                meta: {
                    title: 'Gestions des familles de produits pour les exports journaliers'
                },
                component: () => import('~/views/business/families/Daily.vue')
            },
            {
                path: 'business-exports-families-financial',
                name: 'business-families-financial',
                meta: {
                    title: 'Gestions des familles de produits pour les exports financiers'
                },
                component: () => import('~/views/business/families/Financial.vue')
            },
            {
                path: 'business-exports-daily',
                name: 'business-exports-daily',
                meta: {
                    title: 'Exports journaliers'
                },
                component: () => import('~/views/business/exports/Daily.vue')
            },
            {
                path: 'business-gift-manager',
                name: 'business-gift-manager',
                meta: {
                    title: 'Bons cadeaux'
                },
                component: () => import('~/views/business/giftmanager/Index.vue')
            },
            {
                path: 'plancha',
                name: 'business-gift-manager-plancha',
                meta: {
                    title: 'Mes planches de bons cadeaux'
                },
                component: () => import('~/views/business/giftmanager/plancha/Index.vue')
            },
            {
                path: 'plancha/:id',
                name: 'business-gift-manager-plancha-create',
                meta: {
                    title: 'Créer une planche de bons cadeaux'
                },
                component: () => import('~/views/business/giftmanager/plancha/Create.vue')
            },
            {
                path: 'business-discounts-manager/templates',
                name: 'business-discounts-manager-templates',
                meta: {
                    title: 'Modèles de promotions'
                },
                component: () => import('~/views/business/discounts/Index.vue')
            },
            {
                path: 'business-discounts-manager/templates/edit/:id?',
                name: 'business-gift-manager-template-edit',
                meta: {
                    title: 'Éditer un modèle de promotions'
                },
                component: () => import('~/views/business/discounts/EditDiscountTemplate.vue')
            },
            {
                path: 'business-discounts-manager/show',
                name: 'business-discounts-manager-show',
                meta: {
                    title: 'Codes promotionnels'
                },
                component: () => import('~/views/business/discounts/Show.vue')
            }
        ]
    }
]
