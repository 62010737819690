// moment
import moment from 'moment'
// api
import AccountsApi from '~/api/accounts'
import ActivitiesApi from '~/api/activities'
import ProductsApi from '~/api/products'
import StatsApi from '~/api/stats'
// store modules
import bilansperiodiques from './bilansperiodiques'
import frequentations from './frequentations'
import plannings from './plannings'
import SC from '~/utils/stats/consts'

const initialState = () => ({
    filter: {

        conditionsSatisfied: true,

        /// //////////// Time Scale for all stats views :
        selectedTimeScale: SC.TimeScales.SEASON,
        selectedStepMode: SC.StepModes.BY_DAY,
        timeScaleBegin: moment().startOf('year'),
        timeScaleEnd: moment().endOf('year'),

        /// //////////// Year/Season selector for Activity Analysis View :
        selectedSeason: moment().year().toString(),

        /// //////////// Selected values shared between views and used by filters :
        selectedActivitiesFamilies: null,
        selectedActivities: null,
        selectedActivity: null,

        recursiveProductsFamilies: false,
        selectedProductsFamilies: null,
        selectedProducts: null,

        selectedClients: null,

        selectedSellers: null,

        selectedTurnstiles: null,

        // Spa
        selectedUniverses: null,
        selectedPersonnels: null,

        /// //////////// Years Picker :
        annees_choisissables: null, // Used by all "v-menu" with years (date to choose)

        /// //////////// Activities & Activities' Families Picker :

        activites: [],
        familles: [],

        /// //////////// Animateurs Picker :

        animateurs: [],

        /// //////////// Horaires Picker :

        horaires: [],

        /// //////////// Articles Picker :

        articles: [],

        /// //////////// Colors to analyse activities performances (booking rate, etc.) :

        colorVeryBad: '#FF0000', // red
        colorBad: '#FFA500', // orange
        colorGood: '#FFFF00', // yellow
        colorVeryGood: '#008000', // green

        colorVeryLow: 'indigo lighten-3',
        colorLow: 'indigo lighten-1',
        colorHigh: 'indigo darken-2',
        colorVeryHigh: 'indigo darken-4',

        /// //////////// Colors to compare activities evolutions between time periods :

        colorNone: 'grey',
        colorBetter: 'green',
        colorWorst: 'red',
        colorSame: 'blue'
    }
})

export default {
    modules: {
        bilansperiodiques,
        frequentations,
        plannings
    },
    state: initialState,
    getters: {
        statsFilterResa: state => state.filter
    },
    actions: {

        reset_stats_resa (state) {
            const initial = initialState()
            Object.keys(initial).forEach(key => { state[key] = initial[key] })
        },

        /// //////////// Years Checkers : //////////////////////////////////////////////////////////////////////////////////////////

        checkIfYearsWithDataAreLoaded ({ commit }) {
            return new Promise((resolve, reject) => {
                StatsApi.getStatsResaYearsWithData().then(res => {
                    commit('set_annees_choisissables', [])
                    if (res.data.Res && res.data.Res.length > 0) {
                        res.data.Res.forEach(function (element) {
                            commit('add_annees_choisissables', element)
                        })
                    } else {
                        console.warn('Attention : aucune année choisissables pour les statistiques ! Initialisation avec l\'année courante.')
                        commit('add_annees_choisissables', moment().year().toString())
                    }
                    resolve(res)
                }).catch(err => {
                    console.error('PB in Vue.js fct checkIfYearsWithDataAreLoaded() => StatsApi.getStatsResaYearsWithData()')
                    reject(err)
                })
            })
        },

        checkIfYearsWithDataAreLoadedNP ({ commit }) {
            StatsApi.getStatsResaYearsWithData().then(res => {
                commit('set_annees_choisissables', [])
                if (res.data.Res && res.data.Res.length > 0) {
                    res.data.Res.forEach(function (element) {
                        commit('add_annees_choisissables', element)
                    })
                } else {
                    console.warn('Attention : aucune année choisissables pour les statistiques ! Initialisation avec l\'année courante.')
                    commit('add_annees_choisissables', moment().year().toString())
                }
            }, () => {
                console.error('PB in Vue.js fct checkIfYearsWithDataAreLoaded() => StatsApi.getStatsResaYearsWithData()')
            })
        },

        checkIfFirstYearWithDataIsLoaded ({ commit }) {
            if (this.state.stats.resa.frequentations.filter.mode_annee_du_annee === null ||
                this.state.stats.resa.frequentations.filter.mode_mois_du_annee === null ||
                this.state.stats.resa.frequentations.filter.mode_semaine_du_annee === null) {
                StatsApi.getStatsResaFirstYearWithData().then(res => {
                    commit('set_mode_annee_du_annee', res.data)
                    commit('set_mode_mois_du_annee', res.data)
                    commit('set_mode_semaine_du_annee', res.data)
                }, () => {
                    console.error('PB in Vue.js fct checkIfFirstYearWithDataIsLoaded() => StatsApi.getStatsResaFirstYearWithData()')
                })
            }
        },

        checkIfLastYearWithDataIsLoaded ({ commit }) {
            if (this.state.stats.resa.frequentations.filter.mode_annee_au_annee === null ||
                this.state.stats.resa.frequentations.filter.mode_mois_au_annee === null ||
                this.state.stats.resa.frequentations.filter.mode_semain_au_annee === null ||
                this.state.stats.resa.frequentations.filter.mode_saison_annee === null) {
                StatsApi.getStatsResaLastYearWithData().then(res => {
                    commit('set_mode_annee_au_annee', res.data)
                    commit('set_mode_mois_au_annee', res.data)
                    commit('set_mode_semaine_au_annee', res.data)
                    commit('set_mode_saison_annee', res.data)
                }, () => {
                    console.error('PB in Vue.js fct checkIfLastYearWithDataIsLoaded() => StatsApi.getStatsResaLastYearWithData()')
                })
            }
        },

        /// //////////// Animateurs Picker : //////////////////////////////////////////////////////////////////////////////////////////

        getStatsLoadTrainers ({ commit }) {
            return new Promise((resolve, reject) => {
                AccountsApi.getTrainers().then(res => {
                    commit('set_animateurs', [])
                    res.data.forEach(function (element) {
                        commit('add_animateurs', element)
                    })
                    const tmp = this.state.stats.resa.filter.animateurs.sort(function (x, y) {
                        const a = x.Firstname + ' ' + x.Lastname
                        const b = y.Firstname + ' ' + y.Lastname
                        if (a > b) {
                            return 1
                        }
                        if (b > a) {
                            return -1
                        }
                        return 0
                    })
                    commit('set_animateurs', tmp)
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        /// //////////// Activités Picker : //////////////////////////////////////////////////////////////////////////////////////////

        getStatsLoadActivities ({ commit }) {
            return new Promise((resolve, reject) => {
                ActivitiesApi.getActivities().then(res => {
                    commit('set_activites', [])
                    res.data.forEach(function (element) {
                        if (element.Label.trim()) {
                            commit('add_activites', element)
                        }
                    })
                    const tmp = this.state.stats.resa.filter.activites.sort(function (x, y) {
                        const a = x.Label
                        const b = y.Label
                        if (a > b) {
                            return 1
                        }
                        if (b > a) {
                            return -1
                        }
                        return 0
                    })
                    commit('set_activites', tmp)
                    if (this.state.stats.resa.filter.activites && this.state.stats.resa.filter.activites.length) {
                        if (!this.state.stats.resa.filter.selectedActivity || this.state.stats.resa.filter.selectedActivity === '') {
                            commit('set_selectedActivity', this.state.stats.resa.filter.activites[0])
                        }

                        if (!this.state.stats.resa.filter.selectedActivities || !this.state.stats.resa.filter.selectedActivities.length) {
                            commit('set_selectedActivities', [this.state.stats.resa.filter.activites[0]])
                        }
                    }
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getStatsLoadFamilies ({ commit }) {
            return new Promise((resolve, reject) => {
                ActivitiesApi.getActivitiesFamilies().then(res => {
                    commit('set_familles', [])
                    res.data.forEach(function (element) {
                        if (element.Label.trim()) {
                            commit('add_familles', element)
                        }
                    })
                    const tmp = this.state.stats.resa.filter.familles.sort(function (x, y) {
                        const a = x.Label
                        const b = y.Label
                        if (a > b) {
                            return 1
                        }
                        if (b > a) {
                            return -1
                        }
                        return 0
                    })
                    commit('set_familles', tmp)
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        /// //////////// Articles Picker : //////////////////////////////////////////////////////////////////////////////////////////

        getStatsLoadProducts ({ commit }) {
            return new Promise((resolve, reject) => {
                ProductsApi.getProducts().then(res => {
                    commit('set_articles', [])
                    res.data.Products.forEach(function (element) {
                        if (element.Label.FR.trim()) {
                            commit('add_articles', element)
                        }
                    })
                    const tmp = this.state.stats.resa.filter.articles.sort(function (x, y) {
                        const a = x.Label.FR
                        const b = y.Label.FR
                        if (a > b) {
                            return 1
                        }
                        if (b > a) {
                            return -1
                        }
                        return 0
                    })
                    commit('set_articles', tmp)
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        }
    },
    mutations: {
        /// //////////// Time Scale Selector :

        set_selectedTimeScale (state, val) {
            state.filter.selectedTimeScale = val
        },

        set_selectedStepMode (state, val) {
            state.filter.selectedStepMode = val
        },

        set_timeScaleBegin (state, val) {
            state.filter.timeScaleBegin = val
        },

        set_timeScaleEnd (state, val) {
            state.filter.timeScaleEnd = val
        },

        /// //////////// Season Selector :

        set_selectedSeason (state, val) {
            state.filter.selectedSeason = val
        },

        /// //////////// Activity Selector :

        set_selectedActivity (state, val) {
            state.filter.selectedActivity = val
        },

        set_selectedActivities (state, val) {
            state.filter.selectedActivities = val
        },

        /// //////////// Clients Selector :

        set_selectedClients (state, val) {
            state.filter.selectedClients = val
        },

        add_selectedClients (state, val) {
            state.filter.selectedClients.push(val)
        },

        /// //////////// Recursive Products Families :

        set_recursiveProductsFamilies (state, val) {
            state.filter.recursiveProductsFamilies = val
        },

        /// //////////// Products Families Selector :

        set_selectedProductsFamilies (state, val) {
            state.filter.selectedProductsFamilies = val
        },

        add_selectedProductsFamilies (state, val) {
            state.filter.selectedProductsFamilies.push(val)
        },

        /// //////////// Products Selector :

        set_selectedProducts (state, val) {
            state.filter.selectedProducts = val
        },

        add_selectedProducts (state, val) {
            state.filter.selectedProducts.push(val)
        },

        /// //////////// Sellers Selector :

        set_selectedSellers (state, val) {
            state.filter.selectedSellers = val
        },

        /// //////////// Spa Univers Selector :

        set_selectedUniverses (state, val) {
            state.filter.selectedUniverses = val
        },

        /// //////////// Spa Personnels Selector :

        set_selectedPersonnels (state, val) {
            state.filter.selectedPersonnels = val
        },

        /// //////////// Turnstiles Picker :

        set_selectedTurnstiles (state, val) {
            state.filter.selectedTurnstiles = val
        },
        add_selectedTurnstiles (state, val) {
            state.filter.selectedTurnstiles.push(val)
        },

        /// //////////////////////////////////////////////////////////////////////////

        /// //////////// Years Picker :

        set_annees_choisissables (state, val) {
            state.filter.annees_choisissables = val
        },
        add_annees_choisissables (state, val) {
            state.filter.annees_choisissables.push(val)
        },

        /// //////////// Animateurs Picker :

        set_animateurs (state, val) {
            state.filter.animateurs = val
        },
        add_animateurs (state, val) {
            state.filter.animateurs.push(val)
        },

        /// //////////// Horaires Picker :

        set_horaires (state, val) {
            state.filter.horaires = val
        },
        add_horaires (state, val) {
            state.filter.horaires.push(val)
        },

        /// //////////// Activités Picker :

        set_activites (state, val) {
            state.filter.activites = val
        },
        add_activites (state, val) {
            state.filter.activites.push(val)
        },

        /// //////////// Familles d'activités Picker :

        set_familles (state, val) {
            state.filter.familles = val
        },
        add_familles (state, val) {
            state.filter.familles.push(val)
        },

        /// //////////// Articles Picker :

        set_articles (state, val) {
            state.filter.articles = val
        },
        add_articles (state, val) {
            state.filter.articles.push(val)
        }
    }
}
