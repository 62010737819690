const out = {
    TimeScales: {
        DAY: 1,
        WEEK: 2,
        MONTH: 3,
        // YEAR: 4, // hide for BrickTimeScale.vue
        SEASON: 5,
        CUSTOM: 6
    },
    TimeScalesLabels: {
        1: 'Jour',
        2: 'Semaine',
        3: 'Mois',
        // 4: 'Année', // hide for BrickTimeScale.vue
        5: 'Saison',
        6: 'Personnalisé'
    },
    TimeScalesValues: {
        DAY: 'day',
        WEEK: 'week',
        MONTH: 'month',
        YEAR: 'year',
        SEASON: 'season',
        CUSTOM: 'custom'
    },

    StepModes: {
        BY_YEAR: 0,
        BY_MONTH: 1,
        BY_WEEK: 2,
        BY_DAY: 3,
        BY_SEASON: 4,
        BY_HOUR: 5
    },
    StepModesLabels: {
        0: 'par année',
        1: 'par mois',
        2: 'par semaine',
        3: 'par jour',
        4: 'par saison',
        5: 'par heure'
    },
    StepModesValues: {
        BY_YEAR: 'byYear',
        BY_MONTH: 'byMonth',
        BY_WEEK: 'byWeek',
        BY_DAY: 'byDay',
        BY_SEASON: 'bySeason',
        BY_HOUR: 'byHour'
    },

    // TODO: Replace it
    DateModeChosen: {
        BY_YEAR: 0,
        BY_MONTH: 1,
        BY_WEEK: 2,
        BY_DAY: 3,
        BY_SEASON: 4,
        BY_HOUR: 5
    },
    // TODO: Replace it
    DateModeStep: {
        BY_YEAR: 'byYear',
        BY_MONTH: 'byMonth',
        BY_WEEK: 'byWeek',
        BY_DAY: 'byDay',
        BY_SEASON: 'bySeason',
        BY_HOUR: 'byHour'
    },
    PassingsOptions: {
        TYPES: 1,
        FAMILIES: 2,
        PRODUCTS: 3
    },
    TrainersOptions: {
        COUNT_BOOKINGS_SLOTS: 1,
        COUNT_BOOKINGS: 2,
        COUNT_BOOKINGS_SLOTS_WITHOUT_ACCOUNT: 3,
        COUNT_PARTICIPATIONS: 4,
        RATE_BOOKINGS_PER_PLACES_ON_SALES: 5,
        RATE_PARTICIPATIONS_PER_PLACES_ON_SALES: 6,
        RATE_PARTICIPATIONS_PER_BOOKINGS: 7,
        COUNT_WORKING_HOUR: 8,
        COUNT_WORKING_MINUTE: 9
    }
}

out.TimeScalesKP = []
for (const i in out.TimeScalesLabels) {
    const val = out.TimeScalesLabels[i]
    out.TimeScalesKP.push({ value: parseInt(i), text: val })
}

out.StepModesKP = []
for (const i in out.StepModesLabels) {
    const val = out.StepModesLabels[i]
    out.StepModesKP.push({ value: parseInt(i), text: val })
}

export default out
