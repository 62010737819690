import dateHelper from '../helpers/date'
import ui, {
    daysOfWeek,
    daysOfMonth,
    exportFrequencies
} from '../ui/date'

export default {
    data: () => ({
        daysOfWeek,
        daysOfMonth,
        exportFrequencies
    }),

    methods: {
        ...dateHelper,
        ...ui,
    }
}
