import moment from 'moment'

moment.locale('fr')

export default {
    getDisableActivities(activities, activitiesId) {
        const disable = []
        if (Array.isArray(activitiesId)) {
            activitiesId.forEach(activityId => {
                const activity = activities.find(act => act.ID === activityId)
                if (activity !== undefined && !activity.State) {
                    disable.push(activity)
                }
            })
        }
        return disable
    },

    activityDuration(activity, removeMarginBefore = false, removeMarginAfter = false) {
        let duration = activity.Duration
        if (!removeMarginBefore) {
            duration += activity.MarginDurationBefore
        }
        if (!removeMarginAfter) {
            duration += activity.MarginDurationAfter
        }
        return duration
    },

    activityEndMoment(spaBooking, activity) {
        if (!activity) {
            return moment(spaBooking.Timestamp)
        }
        const start = moment(spaBooking.Timestamp)
        const duration = this.activityDuration(activity, spaBooking.RemoveMarginBefore, spaBooking.RemoveMarginAfter)
        return start.add(duration, 'm')
    }
}
