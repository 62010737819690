import clientHelper from '../helpers/client'
import ui, { 
    clientsGenders,
    clientFields
} from '../ui/client'

export default {
    data: () => ({
        clientsGenders,
        clientFields
    }),

    methods: {
        ...clientHelper,
        ...ui,
    }
}
