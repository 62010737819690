import './common'
import axios from 'axios'

export default {
    getTemplates: (filters) => axios.get('/templates', { params: filters }),
    getTemplate: (id) => axios.get(`/templates/${id}`),
    createTemplate: (data) => axios.post('/templates', data),
    updateTemplate: (id, data) => axios.put(`/templates/${id}`, data),
    getTemplatePreview: (id) => axios.get(`/templates/${id}/preview`, { responseType: 'blob' }),
    getDynamicValues: (id) => axios.get('/templates-dynamic-values')

}
