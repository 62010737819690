import './common'
import axios from 'axios'

export default {
    getModules: () => axios.get('config.modules.json', { baseURL: '/' }),

    getParams: () => axios.get('/resa-params'),
    postParams: (data) => axios.post('/resa-params', data),

    updateMailer: () => axios.get('/params/update-mailer'),
    updateSMSer: () => axios.get('/params/update-smser'),
    getMailerPreview: (previewType) => axios.get(`/params/mailer-preview/${previewType}`),

    getGlobalParams: () => axios.get('/global-params'),
    postGlobalParams: (data) => axios.post('/global-params', data),

    getPeriods: (params) => axios.get('/periods', { params }),
    getPricing: (params) => axios.get('/pricing', { params }),
    getPaymentType: (params) => axios.get('/paymenttype', { params }),
    getEstablishments: () => axios.get('/establishments'),
    getEstablishment: (id) => axios.get(`/establishments/${id}`),
    postEstablishment: (data) => axios.post('/establishments', data),
    putEstablishment: (data) => axios.put(`/establishments/${data.ID}`, data)

}
