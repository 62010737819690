import './common'
import axios from 'axios'

export default {
    getProducts: (params) => axios.get('/products', { params }),
    getProductsStats: (params) => axios.get('/products-stats', { params }),
    getProductsByIDs: (params) => axios.get('/products-by-ids', { params }),
    getPaginatedProducts: (params) => axios.get('/products-paginated', { params }),
    getFamilies: (params) => axios.get('/families', { params }),
    getCardTypes: () => axios.get('/card-types'),
    getProductsStockConsumption: (params) => axios.get('/products-stock-consumption', { params }),
    pickFromStock: (data) => axios.post('/products-stock-consumption', data),
    getFamiliesTreeWithProducts: (params) => axios.get('/families-tree-with-products', { params }),

    getProduct: (id) => axios.get(`/products/${id}`),
    putProduct: (productUUID, data) => axios.put(`/products/${productUUID}`, data),
    postFamily: (data) => axios.post('/families', data),
    putFamily: (familyUUID, data) => axios.put(`/families/${familyUUID}`, data)
}
